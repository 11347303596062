import { ChangeEventHandler, FC, useCallback } from "react";
import { useFormikContext } from "formik";

import { createTranslation, TranslationNS } from "translation";

import ChecksGroup from "../../atoms/Checks/ChecksGroup";
import Company from "./Company";
import { fields as f } from "./fields";
import Personal from "./Personal";
import { ShareDetailsFormValues } from "./ReceiverDetails.types";

const t = createTranslation(TranslationNS.common, "molecules.sharedDetails");

type CustomTitles = {
  owningCustomTitle?: string;
  personCustomTitle?: string;
  companyCustomTitle?: string;
};

type ReceiverDetailsProps = {
  optionalFields?: Array<f>;
  isNewStockOptions?: boolean; //TEMP value required to hide info messages for org number
  isSearchable?: boolean;
  customTitles?: CustomTitles;
};

// Important notice: this component only works inside formik
const ReceiverDetails: FC<ReceiverDetailsProps> = ({
  isSearchable,
  optionalFields,
  isNewStockOptions,
  customTitles,
}) => {
  const { values, setFieldValue } = useFormikContext<ShareDetailsFormValues>();

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      setFieldValue(e.target.name, e.target.value === "company");
    },
    [setFieldValue]
  );

  return (
    <div data-testid="share-details-from-id">
      <ChecksGroup className="mb-4" label={customTitles?.owningCustomTitle || t("howShouldTheSharesBeOwned")}>
        <ChecksGroup.Check
          isNoFocus
          type="radio"
          label={customTitles?.personCustomTitle || t("ownedPersonally")}
          checked={!values[f.isCompanyOwned]}
          name={f.isCompanyOwned}
          value={"person"}
          onChange={handleChange}
        />
        <ChecksGroup.Check
          isNoFocus
          type="radio"
          label={customTitles?.companyCustomTitle || t("throughTheCompany")}
          checked={values[f.isCompanyOwned]}
          name={f.isCompanyOwned}
          value={"company"}
          onChange={handleChange}
        />
      </ChecksGroup>
      {!values[f.isCompanyOwned] ? (
        <Personal isSearchable={isSearchable} optionalFields={optionalFields} />
      ) : (
        <Company isSearchable={isSearchable} optionalFields={optionalFields} isNewStockOptions={isNewStockOptions} />
      )}
    </div>
  );
};

export default ReceiverDetails;
