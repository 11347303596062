import { FC, useCallback, useState } from "react";
import { useFormikContext } from "formik";

import TextField from "common/components/atoms/TextField/TextField";
import { createTranslation, TranslationNS } from "translation";

import ShareholderSearchV2 from "../ShareholderSearchV2/ShareholderSearchV2";
import { SearchStakeholderResult, SearchUser } from "../ShareholderSearchV2/types";
import { fields as f } from "./fields";
import { ShareDetailsFormValues } from "./ReceiverDetails.types";

const t = createTranslation(TranslationNS.common, "molecules.sharedDetails.business");

type PropsTypes = {
  isSearchable?: boolean;
  isOptional?: boolean;
  selectedSearchUser: SearchStakeholderResult | null;
  onChangeUser: (user: SearchStakeholderResult | null) => void;
};

const SearchableCompanyNameField: FC<PropsTypes> = ({ isOptional, isSearchable, selectedSearchUser, onChangeUser }) => {
  const { values, errors, touched, handleChange, handleBlur } = useFormikContext<ShareDetailsFormValues>();

  if (!isSearchable) {
    return (
      <TextField
        isOptional={isOptional}
        label={t("organizationName")}
        value={values[f.companyName]}
        error={errors[f.companyName]}
        isTouched={touched[f.companyName]}
        name={f.companyName}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    );
  }

  return (
    <ShareholderSearchV2
      label={t("organizationName")}
      placeholder=""
      selectedUser={selectedSearchUser}
      onSelect={onChangeUser}
      filterBy="companies"
      name={f.companyName}
      onBlur={handleBlur}
      error={errors[f.companyName]}
      isTouched={touched[f.companyName]}
      hideEmptyResultsMenu
    />
  );
};

export default SearchableCompanyNameField;
