import Button from "common/components/atoms/Button/Button";
import ModalInfo from "common/components/atoms/ModalInfo/ModalInfo";
import { P } from "common/components/atoms/Typography";

const ShareholderSearchModal = ({ show, handleClose }: { show?: boolean; handleClose?: () => void }) => {
  const renderFooter = () => {
    return (
      <>
        <Button>Update shareholder</Button>
        <Button variant="secondary">Register as new</Button>
      </>
    );
  };

  return (
    <ModalInfo
      show={Boolean(show)}
      header="This person is already registered"
      footer={renderFooter()}
      handleClose={handleClose}
    >
      <P.m>
        Would you like to update the current shareholder or register as a new one? If you choose to register as new,
        please note that it will result in a duplicate on the cap table.
      </P.m>
    </ModalInfo>
  );
};

export default ShareholderSearchModal;
