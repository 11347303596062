import { FC, useCallback, useState } from "react";

import { P, Ui } from "common/components/atoms/Typography";
import { VestingTypesEnum } from "common/enums/enum";
import useCurrencyById from "common/hooks/useCurrencyById";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import AnimatedExplanation from "../../common/animated-explanation/animated-explanation";
import { ReceiveRsaPlan } from "../../common/types";

type PropsTypes = {
  className?: string;
  planDetails: ReceiveRsaPlan;
};

const [tPlan, t] = [
  createTranslation(TranslationNS.pages, "company.receivePlan"),
  createTranslation(TranslationNS.pages, "company.receivePlan.RSA"),
];

// const MONTHS_PER_YEAR = 12;

const RSAInfo: FC<PropsTypes> = ({ className, planDetails }) => {
  const { currencySymbol } = useCurrencyById(planDetails?.currencyId);
  const fNumber = useFormatNumbers(currencySymbol);

  const [isExplanationOpen, setIsExplanationOpen] = useState<boolean>(false);

  const isExistingPlan = Boolean(planDetails?.existingPlan);

  const toggleExplanation = useCallback(() => setIsExplanationOpen((state) => !state), []);

  // const adjustedVestingPeriod = Math.ceil(R.defaultTo(0, planDetails?.vestingPeriod) / MONTHS_PER_YEAR);

  return (
    <div className={className}>
      <Ui.m className="mb-3">
        {isExistingPlan
          ? t.el("planMainInfoExisting", {
              values: {
                senderName: planDetails.planCreatorName,
                companyName: planDetails.companyName,
                grantedShares: fNumber(planDetails.totalShares, "amount"),
                valuedAtShares: fNumber(planDetails.pricePerShare, "sharePrice"),
              },
              components: [
                <span
                  key="1"
                  className="cursor-pointer bold"
                  style={{ color: scssVariables.information500 }}
                  onClick={toggleExplanation}
                />,
              ],
            })
          : t.el("planMainInfo", {
              values: {
                senderName: planDetails.planCreatorName,
                companyName: planDetails.companyName,
                grantedShares: fNumber(planDetails.totalShares, "amount"),
                pricePerShare: fNumber(planDetails.purchasePricePerShare, "sharePrice"),
                valuedAtShares: fNumber(planDetails.pricePerShare, "sharePrice"),
              },
              components: [
                <span
                  key="1"
                  className="cursor-pointer bold"
                  style={{ color: scssVariables.information500 }}
                  onClick={toggleExplanation}
                />,
              ],
            })}
      </Ui.m>

      <AnimatedExplanation isOpen={isExplanationOpen} title={t("whatIsRSA.title")} description={t("whatIsRSA.body")} />

      {!isExistingPlan ? (
        <>
          <P.m className="mb-3">
            {t("priceToPay", {
              amountToPay: fNumber(planDetails?.totalPurchasePrice, "value"),
              pricePerShare: fNumber(planDetails?.purchasePricePerShare, "sharePrice"),
            })}
          </P.m>
          <P.m className="mb-3" italic>
            {t("taxReminder")}
          </P.m>
        </>
      ) : null}

      <Ui.m bold className="mb-1">
        {tPlan("vestingTitle")}
      </Ui.m>
      <P.m className="mb-3">
        {(planDetails.vestingTypeId === VestingTypesEnum.TIME_VESTING ||
          planDetails.vestingTypeId === VestingTypesEnum.COMBINE_VESTING) &&
          t("vestingTimebased", {
            firstShares: planDetails?.sharesOnCliff,
            cliff: tPlan("cliffMonthValue", {
              count: planDetails?.vestingCliff,
            }),
            percentageFirstShares: planDetails?.cliffSharePercentage,
            vestingInterval: tPlan("cliffMonthValue", {
              count: planDetails?.vestingInterval,
            }),
            vestingPeriod: planDetails?.vestingPeriod + " months",
          })}
        {planDetails.vestingTypeId === VestingTypesEnum.MILESTONE_VESTING && t("vestingMilestones")}
      </P.m>
      {planDetails.vestingTypeId === VestingTypesEnum.COMBINE_VESTING && (
        <P.m className="mb-3">{t("vestingCombinedAddition")}</P.m>
      )}
    </div>
  );
};

export default RSAInfo;
