import { FC, Fragment } from "react";
import { Image } from "react-bootstrap";
import cn from "classnames";

import { Button, P, Ui } from "common/components/atoms";
import lockImage from "common/icons/lock.png";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { createTranslation, TranslationNS } from "translation";

import classes from "./NoSubscription.module.scss";

const t = createTranslation(TranslationNS.common, "noSubscription");

const contactUsLink = "https://www.unlisted.ai/kontakt-oss";
const viewFeaturesLink = "https://www.unlisted.ai/priser";

const NoSubscription: FC = () => {
  return (
    <PageContent>
      <div className={classes.container}>
        <div className="d-flex justify-content-center">
          <div>
            <Ui.xl bold className="mb-3">
              {t("title")}
            </Ui.xl>

            <P.m>
              {t.el("description", {
                components: [
                  <Fragment key={1}>
                    <br />
                    <br />
                  </Fragment>,
                ],
              })}
            </P.m>
          </div>

          <Image className={cn("ms-5", classes.image)} alt="lock image" src={lockImage} />
        </div>

        <div className={cn("mt-5", classes.actions)}>
          <a href={contactUsLink} target="_blank" rel="noreferrer">
            <Button size="s">{t("contactUs")}</Button>
          </a>

          <a href={viewFeaturesLink} target="_blank" rel="noreferrer" className="ms-3">
            <Button size="s" variant="secondary">
              {t("viewFeatures")}
            </Button>
          </a>
        </div>
      </div>
    </PageContent>
  );
};

export default NoSubscription;
