import { FC, useCallback, useMemo } from "react";
import { Form, useFormikContext } from "formik";

import { Helper, P, SlidePanel } from "common/components/atoms";
import { fields, initialOptionalReceiverDetailsFields } from "common/components/molecules/ReceiverDetails/fields";
import ReceiverDetails from "common/components/molecules/ReceiverDetails/ReceiverDetails";
import ShareholderSearch from "common/components/molecules/ShareholderSearch/ShareholderSearch";
import { PlanDocumentStatusEnum } from "common/enums/enum";
import { ToastFormikValidator } from "common/hooks/useToastFormikValidator";
import GeneratePlanDocument from "common/plan/generatePlanDocument/generatePlanDocument";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import ExerciseClause from "../../../steps/basics/sidebar/form-parts/exercise-clause/exercise-clause";
import VestingPlanDetails from "../../../steps/basics/sidebar/form-parts/VestingPlanDetails/VestingPlanDetails";
import { PlanFormType } from "../../../steps/basics/sidebar/forms/plan-form";
import { PlanForm } from "../../../types";
import Footer from "../../form-parts/footer/footer";
import StockOptionsDetails from "../../form-parts/stock-options/stock-options-details";

type FormDataProps = {
  isEdit?: boolean;
  handleClose: (isOpen: boolean) => void;
};
const t = createTranslation(TranslationNS.pages, "createPlan.basics.planSidebar");

const FormData: FC<FormDataProps> = ({ handleClose, isEdit }) => {
  const selectedProgram = useStoreState((state) => state.programModel.program);

  const { handleSubmit, isSubmitting, values } = useFormikContext<PlanForm>();

  const handleAddPlan = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  const optionalFields = useMemo(() => {
    let initFields = initialOptionalReceiverDetailsFields;
    if (values?.sendEmailInvitation) {
      initFields = initFields.filter((field) => field !== fields.email);
    }
    if (Number(values.documentStatusId) === PlanDocumentStatusEnum.GENERATE && values.isCompanyOwned) {
      initFields = initFields.filter((field) => field !== fields.organizationNumber);
    }
    return initFields;
  }, [values.documentStatusId, values.isCompanyOwned, values?.sendEmailInvitation]);

  return (
    <Form>
      <ToastFormikValidator />
      <Helper>
        <div className="mt-6">
          <Helper.Question questionId="shareholderSearch" type="input">
            <ShareholderSearch noMeta />
          </Helper.Question>
          <Helper.Answer className="mt-3" answerId="shareholderSearch" text={t("shareholderSearchAnswer")} />
        </div>

        <SlidePanel.Section
          title={<Helper.Question questionId="receiverDetails">{t("receiverBasics")}</Helper.Question>}
        >
          <Helper.Answer
            className="mb-3"
            answerId="receiverDetails"
            text={t("receiverDetailsAnswer")}
            linkText={t("receiverDetailsReadMore")}
            link="/"
          />
          <ReceiverDetails isSearchable optionalFields={optionalFields} isNewStockOptions={true} />
        </SlidePanel.Section>

        <SlidePanel.Section data-testid="stockOptionsCollapsible" title={t("stockOptionsDetails")}>
          <StockOptionsDetails
            shareClass={selectedProgram?.shareClassName}
            planType={selectedProgram?.instrumentTypeId}
          />
        </SlidePanel.Section>

        <SlidePanel.Section
          data-testid="vestingDetailsCollapsible"
          title={<Helper.Question questionId="vestingDetails">{t("vestingDetails")}</Helper.Question>}
        >
          <Helper.Answer
            className="mb-3"
            answerId="vestingDetails"
            text={t("vestingDetailsAnswer")}
            linkText={t("vestingDetailsReadMore")}
            link="/"
          />
          <VestingPlanDetails />
        </SlidePanel.Section>

        <SlidePanel.Section
          isDividerVisible
          data-testid="exerciseClauseCollapsible"
          title={<Helper.Question questionId="exerciseClause">{t("exerciseClause")}</Helper.Question>}
        >
          <Helper.Answer
            className="mb-3"
            answerId="exerciseClause"
            text={t("exerciseClauseAnswer")}
            linkText={t("exerciseClauseReadMore")}
            link="/"
          />

          <ExerciseClause md={9} />
        </SlidePanel.Section>

        <SlidePanel.Section
          isDividerVisible={false}
          data-testid="exerciseDocumentCollapsible"
          title={<Helper.Question questionId="document">{t("document")}</Helper.Question>}
        >
          <Helper.Answer answerId="document" text={t("documentAnswer")} />

          <P.m>{t("documentsDescription")}</P.m>

          <GeneratePlanDocument autoGenerateAgreement managerSignature planFormType={PlanFormType.SOPlan} />
        </SlidePanel.Section>

        <Footer isEdit={isEdit} handleAddPlan={handleAddPlan} handleClose={handleClose} isSubmitting={isSubmitting} />
      </Helper>
    </Form>
  );
};
export default FormData;
