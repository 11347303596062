import { FC, Fragment, ReactNode, RefObject, useMemo } from "react";
import cn from "classnames";

import { InfoAlert, P, Ui } from "common/components/atoms";
import Step from "common/components/atoms/Step/Step";
import {
  ArrowUpIcon,
  CommonFileAddIcon,
  DeleteIcon,
  FilledArrowDownIcon,
  InformationCircleIcon,
  SplitIcon,
  SynchronizeArrowsSquareIcon,
  TotalValueIcon,
} from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import { Transaction, TransactionCategory, TransactionCategoryIds, TransactionStatus } from "../../types";
import TransactionItem from "./transaction-item/transaction-item";
import classes from "./transaction-item/transaction-item.module.scss";

type PropsTypes = {
  transactions: Transaction[];
  itemIdToOpen: string | null;
  handleOpenEditTransaction?: (transactionId: number, transactionCategoryId: TransactionCategoryIds) => void;
  handleOpenConfirmTransaction?: (transaction: Transaction) => void;
  handleOpenDeleteTransaction?: (transactionId: number) => void;
  handleOpenDeleteCapitalIncrease?: (capitalIncreaseId: number) => void;
  handleOpenRollbackTransaction?: (transaction: Transaction) => void;
  scrollRef?: RefObject<HTMLUListElement>;
  bundleView?: boolean;
  portfolioView?: boolean;
  isNominalDataUpdates?: boolean;
  setIsNominalValueDataUpdated?: (val: boolean) => void;
};

const t = createTranslation(TranslationNS.pages, "company.transactions");

export const TransactionIcons: Record<TransactionCategoryIds, ReactNode> = {
  [TransactionCategory.Issue]: <CommonFileAddIcon width={24} height={24} />,
  [TransactionCategory.Sell]: <SynchronizeArrowsSquareIcon width={24} height={24} />,
  [TransactionCategory.Split]: <SplitIcon width={24} height={24} />,
  [TransactionCategory.Bundle]: <ArrowUpIcon width={24} height={24} />,
  [TransactionCategory.ChangeNominalValue]: <FilledArrowDownIcon width={24} height={24} />,
  [TransactionCategory.Deletion]: <DeleteIcon width={24} height={24} />,
} as const;

const TransactionsList: FC<PropsTypes> = ({
  transactions,
  handleOpenEditTransaction,
  handleOpenConfirmTransaction,
  handleOpenDeleteTransaction,
  handleOpenDeleteCapitalIncrease,
  handleOpenRollbackTransaction,
  scrollRef,
  itemIdToOpen,
  bundleView = false,
  portfolioView = false,
  isNominalDataUpdates,
  setIsNominalValueDataUpdated,
}) => {
  const hasPendingTransactions = useMemo(
    () => (bundleView ? false : transactions.find((transaction) => transaction.statusId === TransactionStatus.Pending)),
    [bundleView, transactions]
  );

  if (!transactions.length && !bundleView)
    return (
      <div className={classes.empty}>
        <Ui.l bold className="mb-2">
          {t("empty.title")}
        </Ui.l>

        <P.m style={{ color: scssVariables.foregroundMedium }}>
          {t.el(portfolioView ? "empty.portfolioDescription" : "empty.description", {
            components: [
              <Fragment key={1}>
                <br />
                <br />
              </Fragment>,
            ],
          })}
        </P.m>
      </div>
    );

  return (
    <>
      {hasPendingTransactions && !portfolioView && (
        <InfoAlert
          className="p-3 mb-4"
          closable
          customContent={
            <div className="d-flex">
              <InformationCircleIcon height={24} width={56} color={scssVariables.information700} />
              <div className="ms-2">
                <Ui.m bold className="mb-1">
                  {t("pendingTransactionsInfo.title")}
                </Ui.m>
                <Ui.s>{t("pendingTransactionsInfo.content")}</Ui.s>
              </div>
            </div>
          }
        />
      )}

      <Step customRef={scrollRef}>
        {transactions.map((transaction) => {
          const isConfirmedSplit =
            transaction.categoryId === TransactionCategory.Split &&
            transaction.statusId === TransactionStatus.Confirmed;

          const variant = transaction.categoryId === TransactionCategory.Bundle ? "pill" : "circle";

          return (
            <Fragment key={transaction.transactionId}>
              <Step.Item
                id={String(transaction.transactionId)}
                variant={variant}
                status={
                  transaction.statusId === TransactionStatus.Pending ? "pending-transaction" : "committed-transaction"
                }
              >
                <div>
                  <Step.Point
                    icon={
                      transaction.categoryId === TransactionCategory.ChangeNominalValue ? (
                        <TotalValueIcon
                          fontSize={24}
                          color={
                            transaction.statusId === TransactionStatus.Pending
                              ? scssVariables.foregroundMedium
                              : scssVariables.positive900
                          }
                        />
                      ) : (
                        TransactionIcons[transaction.categoryId]
                      )
                    }
                    variant={variant}
                  />
                </div>
                <TransactionItem
                  className="ms-3"
                  transaction={transaction}
                  handleOpenEditTransaction={handleOpenEditTransaction}
                  handleOpenConfirmTransaction={handleOpenConfirmTransaction}
                  handleOpenDeleteTransaction={handleOpenDeleteTransaction}
                  handleOpenDeleteCapitalIncrease={handleOpenDeleteCapitalIncrease}
                  handleOpenRollbackTransaction={handleOpenRollbackTransaction}
                  itemIdToOpen={itemIdToOpen}
                  isNominalDataUpdates={isNominalDataUpdates}
                  setIsNominalValueDataUpdated={setIsNominalValueDataUpdated}
                  portfolioView={portfolioView}
                />
              </Step.Item>
              {isConfirmedSplit && (
                <Ui.xs
                  className={cn(classes.splitDivider, "py-1 px-2 mb-2")}
                >{`${transaction.categoryName} 1:${transaction.multiplier}`}</Ui.xs>
              )}
            </Fragment>
          );
        })}
      </Step>
    </>
  );
};

export default TransactionsList;
