import { FC, MouseEventHandler, useCallback, useEffect, useMemo, useState } from "react";
import { Formik, useFormikContext } from "formik";

import Button from "common/components/atoms/Button/Button";
import SlidePanel from "common/components/atoms/SlidePanel/SlidePanel";
import { Ui } from "common/components/atoms/Typography";
import { initialOptionalReceiverDetailsFields } from "common/components/molecules/ReceiverDetails/fields";
import ReceiverDetails from "common/components/molecules/ReceiverDetails/ReceiverDetails";
import ShareholderSearch from "common/components/molecules/ShareholderSearch/ShareholderSearch";
import { IssueEquityConvertibleStatuses } from "common/enums/enum";
import useToastFormikValidator from "common/hooks/useToastFormikValidator";
import { createTranslation, TranslationNS } from "translation";

import IssueEquityContext from "../../IssueEquityContext";
import LoanDocumentation from "./sections/LoanDocumentation";
import LoanTerms from "./sections/LoanTerms";
import useConvertibleLoanEditPanel from "./useConvertibleLoanEditPanel";

const t = createTranslation(TranslationNS.pages, "company.issueEquity.convertibleLoan.editPanel");

const FormContent = () => {
  useToastFormikValidator();
  const { initialValues, closePanel } = useConvertibleLoanEditPanel();

  const { values, isSubmitting, handleSubmit, setFieldValue } = useFormikContext<typeof initialValues>();

  const optionalFields = useMemo(() => {
    return values.sendInvitationEmail
      ? initialOptionalReceiverDetailsFields.filter((field) => field !== "email")
      : initialOptionalReceiverDetailsFields;
  }, [values.sendInvitationEmail]);

  const handleSaveAsDraft = useCallback(() => {
    setFieldValue("saveAsDraft", true);
    handleSubmit();
  }, [handleSubmit, setFieldValue]);

  return (
    <>
      <div className="mt-6">
        <ShareholderSearch />
      </div>

      <SlidePanel.Section title={t("receiver")}>
        <ReceiverDetails isSearchable optionalFields={optionalFields} />
      </SlidePanel.Section>

      <SlidePanel.Section title={t("terms")}>
        <LoanTerms />
      </SlidePanel.Section>

      <SlidePanel.Section title={t("documentation")} isDividerVisible={false}>
        <LoanDocumentation />
      </SlidePanel.Section>

      <Ui.m italic className="mt-3">
        {t("updatedCapTableDiluted")}
      </Ui.m>

      <SlidePanel.Actions>
        <div className="flex-grow-1">
          <Button
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
            onClick={handleSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
            className="me-3"
          >
            {t("issueConvertibleLoan")}
          </Button>

          <Button isLoading={isSubmitting} isDisabled={isSubmitting} variant="secondary" onClick={closePanel}>
            {t("cancel")}
          </Button>
        </div>
        {values?.statusId !== IssueEquityConvertibleStatuses.Converting &&
          values?.statusId !== IssueEquityConvertibleStatuses.Approved && (
            <Button isLoading={isSubmitting} isDisabled={isSubmitting} variant="tertiary" onClick={handleSaveAsDraft}>
              {t("saveAsDraft")}
            </Button>
          )}
      </SlidePanel.Actions>
    </>
  );
};

const ConvertibleLoanForm: FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { loanId } = IssueEquityContext.useStoreState((state) => state);
  const { getConvertibleLoanThunk } = IssueEquityContext.useStoreActions((actions) => actions);

  const { initialValues, validationSchema, onSubmit } = useConvertibleLoanEditPanel();

  useEffect(() => {
    if (loanId) {
      setIsLoading(true);
      getConvertibleLoanThunk(loanId)
        .finally(() => {
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [getConvertibleLoanThunk, loanId]);

  return (
    <SlidePanel.Body isLoading={isLoading}>
      <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        <FormContent />
      </Formik>
    </SlidePanel.Body>
  );
};

const ConvertibleLoanEditPanel: FC = () => {
  const { isConvertibleLoanEditPanelOpen, loanId } = IssueEquityContext.useStoreState((state) => state);
  const { setIsConvertibleLoanEditPanelOpen } = IssueEquityContext.useStoreActions((actions) => actions);

  const closePanel = useCallback(() => {
    setIsConvertibleLoanEditPanelOpen({ open: false });
  }, [setIsConvertibleLoanEditPanelOpen]);

  return (
    <SlidePanel show={isConvertibleLoanEditPanelOpen}>
      <SlidePanel.Header title={t("title")} isEdit={!!loanId} onHide={closePanel} />
      <ConvertibleLoanForm />
    </SlidePanel>
  );
};

export default ConvertibleLoanEditPanel;
