import { useEffect, useState } from "react";
import { useLocation,useNavigate } from "react-router-dom";

import { useStoreActions, useStoreState } from "../../store/store";
import { publicRouteList, ROUTER_V2 } from "../Router/RouterV2.types";

const useAuthMiddleware = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const instanceFinishedLoading = useStoreState((state) => state.authentication.localCacheChecked);
  const authenticated = useStoreState((state) => state.authentication.isAuthenticated);
  const userStore = useStoreState((state) => state.account.user);
  const { setPublicRouteActive } = useStoreActions((state) => state.authentication);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (instanceFinishedLoading) {
      if (authenticated && userStore) {
        setIsLoading(false);
      } else if (!authenticated) {
        setIsLoading(false);
      }
    }

    const route = location.pathname.split("/")[1];
    const isPublic = publicRouteList.includes(route);

    if (isPublic) {
      setPublicRouteActive(true);
      return;
    }
    setPublicRouteActive(false);

    if (instanceFinishedLoading && !authenticated) {
      navigate(ROUTER_V2.signIn.base);
    }
  }, [authenticated, instanceFinishedLoading, location.pathname, navigate, setPublicRouteActive, userStore]);

  return isLoading;
};

export default useAuthMiddleware;
