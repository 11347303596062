import { FC, useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import { useFormikContext } from "formik";

import DatePicker from "common/components/atoms/DatePicker/DatePicker";
import { newPlanFieldsNames as f } from "common/plan/planFormFields";
import { createTranslation, TranslationNS } from "translation";

import { PlanForm } from "../../../../create-one-off-plans/types";

const t = createTranslation(TranslationNS.pages, "createPlan.basics.planSidebar");

const ApprovalInformation: FC = () => {
  const { values, setFieldValue, handleBlur } = useFormikContext<PlanForm>();

  const handleChaneDatePickers = useCallback(
    (date: Date | string | null, name?: string) => {
      if (name) {
        setFieldValue(name, date);
      }
    },
    [setFieldValue]
  );

  return (
    <Row>
      <Col>
        <DatePicker
          isOptional
          isClearable
          isDateOnlyString
          name={f.approvedAt}
          date={values.approvedAt}
          label={t("boardApprovalDate")}
          onBlur={handleBlur}
          onChange={handleChaneDatePickers}
        />
      </Col>

      <Col>
        <DatePicker
          isDateOnlyString
          name={f.grantedAt}
          label={t("grantDate")}
          date={values.grantedAt}
          onChange={handleChaneDatePickers}
          onBlur={handleBlur}
        />
      </Col>
    </Row>
  );
};

export default ApprovalInformation;
