import { FC } from "react";
import { useFormikContext } from "formik";

import TextField from "common/components/atoms/TextField/TextField";
import ShareholderSearch from "common/components/molecules/ShareholderSearch/ShareholderSearch";
import { createTranslation, TranslationNS } from "translation";

import { fields as f } from "./fields";
import { ShareDetailsFormValues } from "./ReceiverDetails.types";

const t = createTranslation(TranslationNS.common, "molecules.sharedDetails.personal");

const SearchableFirstNameField: FC<{ isSearchable?: boolean; isOptional?: boolean }> = ({
  isSearchable,
  isOptional,
}) => {
  const { values, errors, touched, handleChange, handleBlur } = useFormikContext<ShareDetailsFormValues>();

  if (!isSearchable) {
    return (
      <TextField
        isOptional={Boolean(isOptional)}
        label={t("firstName")}
        value={values[f.firstName]}
        error={errors[f.firstName]}
        isTouched={touched[f.firstName]}
        name={f.firstName}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    );
  }
  return (
    <ShareholderSearch
      noMeta
      showJustUsers
      placeholder=""
      notShowEmptyResults
      updateSearchField
      label={t("firstName")}
      onBlur={handleBlur}
      previousValue={values[f.firstName]}
      name={f.firstName}
      error={errors[f.firstName]}
      isTouched={touched[f.firstName]}
    />
  );
};

export default SearchableFirstNameField;
