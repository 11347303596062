import { FC, useMemo } from "react";

import { Tag, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { ArrowRightIcon, CalendarIcon, CommonFileIcon, DollarCircleIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { Transaction, TransactionStatus } from "pages/equity-management/ownership/transactions/types";
import { createTranslation, TranslationNS } from "translation";

import formatTransactionDate from "../../../format-transaction-date";

type PropsTypes = {
  transaction: Transaction;
};

const tTransactions = createTranslation(TranslationNS.pages, "company.transactions");
const t = createTranslation(TranslationNS.pages, "company.transactions.transactionItem");

const SplitHeader: FC<PropsTypes> = ({ transaction }) => {
  const isPending = useMemo(() => transaction.statusId === TransactionStatus.Pending, [transaction]);

  const fNumber = useFormatNumbers(transaction.currencySymbol);
  return (
    <div>
      <div className="d-flex align-items-center mb-1">
        <Ui.m bold className="me-half">
          {fNumber(transaction.numberOfSharesBefore, "amount")}
        </Ui.m>
        <ArrowRightIcon height={16} width={16} color={scssVariables.foregroundHigh} />
        <Ui.m bold className="ms-half">{`${fNumber(transaction.numberOfShares, "amount")} ${t("existingShares")} • ${
          transaction.categoryName
        } 1:${transaction.multiplier}`}</Ui.m>
      </div>

      <div className="d-flex" style={{ color: scssVariables.foregroundLow }}>
        <Tag className="me-2" variant={isPending ? "closed" : "complete"}>
          <Ui.xs>{transaction.categoryName}</Ui.xs>
        </Tag>

        {isPending ? (
          <Tag variant="information" className="d-flex align-items-center me-2">
            <CalendarIcon height={16} width={16} color={scssVariables.foregroundLow} className="me-half" />
            <Ui.xs>{formatTransactionDate(transaction.transactedAt) + ` • ${tTransactions("pending")}`}</Ui.xs>
          </Tag>
        ) : (
          <div className="d-flex align-items-center me-2">
            <CalendarIcon height={16} width={16} color={scssVariables.foregroundLow} className="me-half" />
            <Ui.xs>{formatTransactionDate(transaction.transactedAt)}</Ui.xs>
          </div>
        )}

        <div className="d-flex align-items-center me-2">
          <DollarCircleIcon />
          <Ui.xs className="mx-half">{`${fNumber(transaction.sharePriceBefore, "sharePrice")}/${t("share")}`}</Ui.xs>
          <ArrowRightIcon height={16} width={16} color={scssVariables.foregroundLow} />
          <Ui.xs className="ms-half">{`${fNumber(transaction.sharePrice, "sharePrice")}/${t("share")}`}</Ui.xs>
        </div>

        {transaction.description && (
          <div className="d-flex align-items-center me-2">
            <CommonFileIcon height={16} width={16} color={scssVariables.foregroundLow} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SplitHeader;
