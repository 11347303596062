import { FC } from "react";
import BDropdown from "react-bootstrap/Dropdown";
import classNames from "classnames";
import { isEmpty } from "ramda";

import { Ui } from "common/components/atoms/Typography";
import { createTranslation, TranslationNS } from "translation";

import DropdownClasses from "../../atoms/Dropdown/Dropdown.module.scss";
import ShareholderSearchDropdownItem, { ShareholderSearchDropdownItemProps } from "./ShareholderSearch.item";
import classes from "./ShareholderSearch.module.scss";
import { SearchStakeholderResult } from "./types";

type PropsTypes = Omit<ShareholderSearchDropdownItemProps, "option" | "index"> & {
  options: SearchStakeholderResult[];
  isSmallFont?: boolean;
};

const t = createTranslation(TranslationNS.common, "molecules.shareholderSearch");

const ShareholderSearchDropdownMenu: FC<PropsTypes> = ({ isSmallFont, searchValue, options = [], handleSelect }) => {
  return (
    <BDropdown.Menu className={classNames(DropdownClasses["menu"], classes["menu-container"])}>
      {!isEmpty(options) ? (
        options.map((option: SearchStakeholderResult, i: number) => (
          <ShareholderSearchDropdownItem
            key={`Dropdown key for single item is: ${option}-${i}`}
            index={i}
            option={option}
            isSmallComponentUsage={isSmallFont}
            searchValue={searchValue}
            handleSelect={handleSelect}
          />
        ))
      ) : searchValue?.trim() ? (
        <div className={classes["empty"]}>
          <Ui.m
            style={{
              fontSize: isSmallFont ? 14 : 16,
            }}
          >
            {t.el("empty", {
              components: [<strong key={1} />],
            })}
          </Ui.m>
        </div>
      ) : null}
    </BDropdown.Menu>
  );
};

export default ShareholderSearchDropdownMenu;
