import { FC, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, FormikConfig } from "formik";
import { clone, omit } from "ramda";

import { getPath } from "app/Router/RouterHelper";
import { OnboardingMethodsEnum } from "common/enums/enum";
import WithRouteAnimation from "common/HOC/WithRouteAnimation";
import { useStoreActions, useStoreState } from "store/store";

import OnboardCompanyContext from "../OnboardCompanyContext";
import useOnboardCompanyIntegration from "../useOnboardCompanyIntegration";
import CompanyInformationForm from "./CompanyInformationForm";
import IncludeTransactionModal from "./IncludeTransactionModal/IncludeTransactionModal";
import { fields, OnboardingCompanyBasicFormValues, useCompanyInformationForm } from "./useCompanyInformationForm";

const CompanyInformation: FC = () => {
  useOnboardCompanyIntegration(false);

  const navigate = useNavigate();
  const { companyId } = useParams<{ companyId?: string }>();

  const [showModal, setShowModal] = useState(false);

  const invitedUser = useStoreState((state) => state.invitedUser.invitedUser);
  const integrations = useStoreState((state) => state.companyOnboardingModel.integrations);
  const setInvitedUser = useStoreActions((actions) => actions.invitedUser.setInvitedUser);
  const setCompanyId = useStoreActions((state) => state.activeCompanyModel.setCompanyId);
  const { setCompanyThunk, setIntegration } = useStoreActions((actions) => actions.companyOnboardingModel);

  const companyType = OnboardCompanyContext.useStoreState((state) => state.companyType);

  const isSimplifyFlow = companyType === "few-owners";

  const { initialValues, validationSchema } = useCompanyInformationForm();

  const handleManageModal = useCallback(() => {
    setShowModal((prev) => !prev);
  }, []);

  const submitHandler = useCallback<FormikConfig<OnboardingCompanyBasicFormValues>["onSubmit"]>(
    async (values, { resetForm }) => {
      try {
        let onboardingMethod = OnboardingMethodsEnum.NO_INTEGRATION;

        if (companyType === "few-owners" && integrations?.bregg) {
          onboardingMethod = OnboardingMethodsEnum.FEW_OWNERS_COMPANY;
        } else if (companyType !== "few-owners" && integrations?.bregg) {
          onboardingMethod = OnboardingMethodsEnum.BR_REG;
        } else if (integrations?.uniMicro && companyType !== "few-owners") {
          onboardingMethod = integrations.uniMicro?.onboardingMethod || OnboardingMethodsEnum.UNI_MICRO;
        } else if (integrations?.uniMicro && companyType === "few-owners") {
          onboardingMethod = OnboardingMethodsEnum.FEW_OWNERS_COMPANY;
        } else if (companyType === "few-owners") {
          onboardingMethod = OnboardingMethodsEnum.FEW_OWNERS_COMPANY;
        }

        const copiedValues = isSimplifyFlow
          ? omit([fields.nominalShareValue], clone(values))
          : omit([fields.numberOfShares], clone(values));

        const company = await setCompanyThunk({
          ...copiedValues,
          companyId: companyId ? Number(companyId) : undefined,
          shareClasses: integrations?.bregg?.shareClasses || integrations?.uniMicro?.shareClasses || [],
          onboardingMethod,
          companyInvitationId: invitedUser?.entityId ? Number(invitedUser?.entityId) : undefined,
        });

        if (company) {
          resetForm();
          setInvitedUser(null);
          setCompanyId(company?.data?.companyId || 0);
          setIntegration({
            type: "regular",
            data: { companyId: company?.data?.companyId || 0, withTransaction: false },
          });

          if (integrations?.bregg) {
            navigate(getPath(["onboard", "company", "companyInformation"], { companyId: company?.data?.companyId }), {
              replace: true,
            });

            if (!isSimplifyFlow) {
              // handleManageModal();
              navigate(getPath(["onboard", "company", "importStakeholders"], { companyId: company?.data?.companyId }));
            } else {
              navigate(getPath(["onboard", "company", "importFounders"], { companyId: company?.data?.companyId }));
            }
          } else if (integrations?.uniMicro) {
            navigate(getPath(["onboard", "company", "companyInformation"], { companyId: company?.data?.companyId }), {
              replace: true,
            });
            if (!isSimplifyFlow) {
              // handleManageModal();
              navigate(getPath(["onboard", "company", "importStakeholders"], { companyId: company?.data?.companyId }));
            } else {
              navigate(getPath(["onboard", "company", "importFounders"], { companyId: company?.data?.companyId }));
            }
          } else if (isSimplifyFlow) {
            navigate(getPath(["onboard", "company", "importFounders"], { companyId: company?.data?.companyId }));
          } else {
            navigate(getPath(["onboard", "company", "shareClasses"], { companyId: company?.data?.companyId }), {
              replace: true,
            });
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    [
      integrations?.bregg,
      integrations.uniMicro,
      companyType,
      setCompanyThunk,
      companyId,
      invitedUser?.entityId,
      setInvitedUser,
      setCompanyId,
      setIntegration,
      navigate,
      isSimplifyFlow,
    ]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submitHandler}
    >
      <>
        <CompanyInformationForm />
        <IncludeTransactionModal show={showModal} handleClose={handleManageModal} />
      </>
    </Formik>
  );
};

export default WithRouteAnimation(CompanyInformation);
