import { FC, useCallback, useState } from "react";

import { P, Ui } from "common/components/atoms/Typography";
import { VestingTypesEnum } from "common/enums/enum";
import useCurrencyById from "common/hooks/useCurrencyById";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { scssVariables } from "common/utils/constants";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { createTranslation, TranslationNS } from "translation";

import AnimatedExplanation from "../../common/animated-explanation/animated-explanation";
import { ReceiveSoPlan } from "../../common/types";

type PropsTypes = {
  className?: string;
  planDetails: ReceiveSoPlan;
};

const [tPlan, t] = [
  createTranslation(TranslationNS.pages, "company.receivePlan"),
  createTranslation(TranslationNS.pages, "company.receivePlan.SO"),
];

const SOInfo: FC<PropsTypes> = ({ className, planDetails }) => {
  const { currencySymbol } = useCurrencyById(planDetails.currencyId);
  const fNumber = useFormatNumbers(currencySymbol);

  const [isExplanationOpen, setIsExplanationOpen] = useState<boolean>(false);

  const isExistingPlan = Boolean(planDetails?.existingPlan);

  const toggleExplanation = useCallback(() => setIsExplanationOpen((state) => !state), []);

  return (
    <div className={className}>
      <Ui.m className="mb-4">
        {isExistingPlan
          ? t.el("planMainInfoExisting", {
              values: {
                senderName: planDetails.planCreatorName,
                companyName: planDetails.companyName,
                grantedShares: fNumber(planDetails.totalShares, "amount"),
              },
              components: [
                <span
                  key="1"
                  className="cursor-pointer bold"
                  style={{ color: scssVariables.information500 }}
                  onClick={toggleExplanation}
                />,
              ],
            })
          : t.el("planMainInfo", {
              values: {
                senderName: planDetails.planCreatorName,
                companyName: planDetails.companyName,
                grantedShares: fNumber(planDetails.totalShares, "amount"),
                pricePerShare: fNumber(planDetails.purchasePricePerShare, "sharePrice"),
              },
              components: [
                <span
                  key="1"
                  className="cursor-pointer bold"
                  style={{ color: scssVariables.information500 }}
                  onClick={toggleExplanation}
                />,
              ],
            })}
      </Ui.m>

      <AnimatedExplanation
        isOpen={isExplanationOpen}
        title={t("whatIsSO.title")}
        description={t("whatIsSO.body")}
        handleClose={() => {
          setIsExplanationOpen(false);
        }}
      />

      <P.m className="my-3">
        {t("priceToPay", {
          exercisePrice: fNumber(planDetails?.exercisePrice, "unitPrice"),
        })}
      </P.m>
      <P.m className="my-3">
        {t("priceToPayTotal", {
          countOfStocks: fNumber(planDetails?.totalShares, "amount"),
          totalExercisePrice: fNumber(planDetails?.totalExercisePrice, "value"),
        })}
      </P.m>
      <Ui.m bold className="mb-1">
        {tPlan("vestingTitle")}
      </Ui.m>
      <P.m className="mb-3">
        {(planDetails.vestingTypeId === VestingTypesEnum.TIME_VESTING ||
          planDetails.vestingTypeId === VestingTypesEnum.COMBINE_VESTING) &&
          t("vestingTimebased", {
            firstShares: planDetails?.sharesOnCliff,
            cliff: tPlan("cliffMonthValue", {
              count: planDetails?.vestingCliff,
            }),
            percentageFirstShares: planDetails?.cliffSharePercentage,
            vestingInterval: tPlan("cliffMonthValue", {
              count: planDetails?.vestingInterval,
            }),
            vestingPeriod: planDetails?.vestingPeriod + " months",
          })}
        {planDetails.vestingTypeId === VestingTypesEnum.MILESTONE_VESTING && t("vestingMilestones")}
      </P.m>
      {planDetails.vestingTypeId === VestingTypesEnum.COMBINE_VESTING && (
        <P.m className="mb-3">{t("vestingCombinedAddition")}</P.m>
      )}
      <Ui.m bold className="mb-1">
        {t("expiry.title")}
      </Ui.m>
      <P.m>
        {t.el("expiry.description", {
          values: { expiryDate: transformDateToCommonDateFormat(planDetails?.exerciseExpiryDate) },
          components: [<strong key={1} />],
        })}
      </P.m>
    </div>
  );
};

export default SOInfo;
