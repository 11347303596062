export enum fields {
  firstName = "firstName",
  lastName = "lastName",
  isCompanyOwned = "isCompanyOwned",
  phoneNumber = "phoneNumber",
  dateOfBirth = "dateOfBirth",
  address = "address",
  countryId = "countryId",
  companyName = "companyName",
  organizationNumber = "organizationNumber",
  email = "email",
  relationshipTypeId = "relationshipTypeId",
  businessPostAddress = "businessPostAddress",
  stakeholderId = "stakeholderId",
}

export const initialOptionalReceiverDetailsFields = [
  fields.phoneNumber,
  fields.address,
  fields.dateOfBirth,
  fields.countryId,
  fields.organizationNumber,
  fields.email,
  fields.businessPostAddress,
];
