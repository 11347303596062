import { FC, useMemo } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useFormikContext } from "formik";

import Helper from "common/components/atoms/Helper/Helper";
import TextField from "common/components/atoms/TextField/TextField";
import { InstrumentTypesIdsEnum, VestingTypesEnum } from "common/enums/enum";
import { CreateProgramFormData } from "pages/equity-management/plans/wizards/create-program/useProgramForm";
import { createTranslation, TranslationNS } from "translation";

import { VestingDetailsProps } from "../VestingDetails";

const t = createTranslation(TranslationNS.common, "program.components.programForm.vestingDetails");

type TimeBasedFieldsProps = VestingDetailsProps;
const TimeBasedFields: FC<TimeBasedFieldsProps> = ({ type, instrumentType }) => {
  const { values, errors, touched, handleChange, handleBlur } = useFormikContext<
    Pick<
      CreateProgramFormData,
      "vestingTypeId" | "vestingPeriod" | "vestingInterval" | "vestingCliff" | "allowAcceleratedVesting"
    > & { timeVestedShares: number }
  >();

  const vestingType = useMemo<VestingTypesEnum | null>(
    () => (values.vestingTypeId ? +values.vestingTypeId : null),
    [values.vestingTypeId]
  );

  if (vestingType !== VestingTypesEnum.TIME_VESTING && vestingType !== VestingTypesEnum.COMBINE_VESTING) {
    return null;
  }

  return (
    <div className="my-4">
      <Row className="mb-5">
        <Col>
          <Helper.Question questionId="vestingPeriod" type="input">
            <TextField
              type="number"
              error={errors.vestingPeriod}
              label={t("vestingPeriod.label")}
              isTouched={touched.vestingPeriod}
              value={values.vestingPeriod}
              meta={t("vestingInterval.description")}
              name="vestingPeriod"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Helper.Question>
        </Col>
        <Col>
          <Helper.Question questionId="vestingInterval" type="input">
            <TextField
              type="number"
              error={errors.vestingInterval}
              label={t("vestingInterval.label")}
              isTouched={touched.vestingInterval}
              value={values.vestingInterval}
              meta={t("vestingInterval.description")}
              name="vestingInterval"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Helper.Question>
        </Col>
      </Row>
      <Row>
        <Col>
          <Helper.Answer
            className="mb-1"
            answerId="vestingPeriod"
            text={t("vestingPeriod.answer")}
            linkText={t("vestingPeriod.readMore")}
            link="/"
          />
          <Helper.Answer
            className="mb-1"
            answerId="vestingInterval"
            text={t("vestingInterval.answer")}
            linkText={t("vestingInterval.readMore")}
            link="/"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Helper.Question questionId="vestingCliff" type="input">
            <TextField
              type="number"
              error={errors.vestingCliff}
              label={t("vestingCliff.label")}
              isTouched={touched.vestingCliff}
              value={values.vestingCliff}
              meta={t("vestingPeriod.description")}
              name="vestingCliff"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Helper.Question>
        </Col>
        <Col>
          {type === "plan" && values.vestingTypeId === VestingTypesEnum.COMBINE_VESTING && (
            <Helper.Question questionId={"numberOfOptions"} type={"input"}>
              <TextField
                type="number"
                label={t(
                  `numberOfOptions.label${instrumentType === InstrumentTypesIdsEnum.RSA ? "Shares" : "Options"}`
                )}
                error={errors.timeVestedShares}
                isTouched={touched.timeVestedShares}
                value={values.timeVestedShares}
                name={"timeVestedShares"}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Helper.Question>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <Helper.Answer
            className="mt-3"
            answerId="vestingCliff"
            text={t("vestingCliff.answer")}
            linkText={t("vestingCliff.readMore")}
            link="/"
          />
          <Helper.Answer
            className="mt-3"
            answerId={"numberOfOptions"}
            text={t("numberOfOptions.answerCombine")}
            linkText={t("numberOfOptions.readMore")}
            link={"/"}
          />
        </Col>
      </Row>
    </div>
  );
};

export default TimeBasedFields;
