import { ChangeEvent, FC, FocusEventHandler, useCallback, useEffect, useMemo, useRef, useState } from "react";
import BDropdown from "react-bootstrap/Dropdown";
import axios from "axios";
import cn from "classnames";
import { clone } from "ramda";

import DropdownClasses from "common/components/atoms/Dropdown/Dropdown.module.scss";
import useDebounce from "common/hooks/useDebounce";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import ShareholderSearchDropdownMenu from "./ShareholderSearch.menu";
import ShareholderSearchModal from "./ShareholderSearch.modal";
import classes from "./ShareholderSearch.module.scss";
import ShareholderToggle from "./ShareholderToggle";
import { FilterBy, SearchStakeholderResponse, SearchStakeholderResult } from "./types";

const t = createTranslation(TranslationNS.common, "molecules.shareholderSearch");

type PropsTypes = {
  onSelect: (shareholder: SearchStakeholderResult | null) => void;
  selectedUser?: SearchStakeholderResult | null;
  filterBy?: FilterBy;
  label?: string;
  placeholder?: string;
  onBlur?: FocusEventHandler<HTMLButtonElement>;
  name?: string;
  error?: string;
  isTouched?: boolean;
  className?: string;
  hideEmptyResultsMenu?: boolean;
  isDisabled?: boolean;
};

/**
 * This component uses external state as source of trues, which user is selected.
 * 1 - it doesn't depend on formik context, so you can use as many search components in the same form is you want.
 * 2 - You can clear this search with external events. (Click on "Remove user" btn, reset form etc.)
 * 3 - Uses correct types
 * 4 - Fill field with selected first name or company name
 * 5 - Much more efficient filtering algorithm
 *
 * All assistants components are duplicated for independence from old version
 */

const ShareholderSearchV2: FC<PropsTypes> = ({
  label = t("label"),
  placeholder = t("placeholder"),
  filterBy = "none",
  hideEmptyResultsMenu = false,
  selectedUser,
  onSelect,
  onBlur,
  name,
  error,
  isTouched,
  className,
  isDisabled,
}) => {
  const { companyId } = useStoreState((state) => state.activeCompanyModel);
  const toggleRef = useRef<HTMLInputElement>(null);
  const [searchFieldValue, setSearchFieldValue] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [matchShareholderModalOpen, setMatchShareholderModalOpen] = useState(false);
  const [searchResults, setSearchResults] = useState<SearchStakeholderResult[]>([]);
  const [canShowResults, setCanShowResults] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [safariInteraction, setSafariInteraction] = useState(false);
  const [preventDebounce, setPreventDebounce] = useState(false);

  const debouncedSearchValue = useDebounce(searchFieldValue, 1000, preventDebounce);

  const filteredOptions = useMemo(() => {
    let sortedSearchResult = clone(searchResults).sort((a, b) => {
      if (selectedUser?.isCompanyOwned) {
        return Number(b.isCompanyOwned) - Number(a.isCompanyOwned);
      } else {
        return Number(a.isCompanyOwned) - Number(b.isCompanyOwned);
      }
    });

    if (filterBy === "users") {
      sortedSearchResult = sortedSearchResult.filter((user) => !user.isCompanyOwned);
    }

    if (filterBy === "companies") {
      sortedSearchResult = sortedSearchResult.filter((user) => user.isCompanyOwned);
    }

    if (debouncedSearchValue) {
      return sortedSearchResult.filter((user: SearchStakeholderResult) => {
        const userDataToSearchAgents = (
          user.firstName +
          " " +
          user.lastName +
          " " +
          user.companyName +
          " " +
          user.organizationNumber
        ).toLowerCase();

        return userDataToSearchAgents.includes(debouncedSearchValue?.toLowerCase());
      });
    }

    return sortedSearchResult;
  }, [searchResults, filterBy, debouncedSearchValue, selectedUser?.isCompanyOwned]);

  useEffect(() => {
    setPreventDebounce(true);
    if (!selectedUser?.companyName && !selectedUser?.firstName && !selectedUser?.lastName) {
      setSearchFieldValue("");
    } else {
      if (filterBy === "companies") {
        setSearchFieldValue(selectedUser.companyName || "");
      }
      if (filterBy === "users") {
        setSearchFieldValue(selectedUser.firstName || "");
      }
      if (filterBy === "none" && selectedUser?.firstName && selectedUser.lastName) {
        setSearchFieldValue(`${selectedUser.firstName} ${selectedUser.lastName}`);
      }
    }
  }, [filterBy, selectedUser]);

  const clearValueData = useCallback(() => {
    toggleRef?.current?.focus();
    onSelect({ isCompanyOwned: selectedUser?.isCompanyOwned });
    setSearchFieldValue("");
  }, [onSelect, selectedUser?.isCompanyOwned]);

  const handleSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPreventDebounce(false);
      setSearchFieldValue(e.target.value);
    },
    [setSearchFieldValue]
  );

  const handleSelect = useCallback(
    (selectedUser: SearchStakeholderResult) => {
      toggleRef?.current?.blur();

      onSelect(selectedUser);

      setIsFocused(false);
      setIsMenuOpen(false);
    },
    [onSelect]
  );

  const searchShareholderRequest = useCallback(async () => {
    setCanShowResults(false);

    if (!debouncedSearchValue?.trim()) {
      setCanShowResults(false);
      setSearchResults([]);
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.post<SearchStakeholderResponse>("/api/equity-management/shareholder/search", {
        companyId,
        searchString: debouncedSearchValue,
      });

      if (response.status === 200) {
        if (response.data.results.length) {
          setSearchResults(response.data.results);
        }

        setCanShowResults(true);
        setIsMenuOpen(true);
        setIsFocused(true);
      }
    } catch (e) {
      console.warn({ e });
    } finally {
      setIsLoading(false);
    }
  }, [companyId, debouncedSearchValue]);

  useEffect(() => {
    searchShareholderRequest();
  }, [searchShareholderRequest]);

  const handleBlur: FocusEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      setIsFocused(false);
      onBlur?.(e);

      // If it's fiend existing search bar, it should not set any value on blur, only on actual select
      if (filterBy === "none") {
        return;
      }

      // Set first name field on blur if user type name which is not existing in search results
      if (filterBy === "users") {
        return onSelect({
          ...selectedUser,
          firstName: searchFieldValue,
        });
      }

      // Set company name field on blur if user type company name which is not existing in search results
      if (filterBy === "companies") {
        return onSelect({
          ...selectedUser,
          companyName: searchFieldValue,
        });
      }
    },
    [filterBy, onBlur, onSelect, searchFieldValue, selectedUser]
  );

  const handleToggle = useCallback(
    (nextShow: boolean) => {
      if (isFocused && !nextShow) {
        setIsMenuOpen(true);
      } else {
        setIsMenuOpen(nextShow);
      }
    },
    [isFocused]
  );

  const handleFocus = useCallback(() => {
    setIsFocused(true);
    if (!safariInteraction) {
      setIsMenuOpen(true);
      setSafariInteraction(true);
    }
  }, [safariInteraction]);

  return (
    <>
      <BDropdown
        show={isMenuOpen}
        className={cn(DropdownClasses["dropdown"], className, DropdownClasses["isSearchable"])}
        onToggle={handleToggle}
      >
        <div className="position-relative">
          <BDropdown.Toggle
            isSearchable
            label={label}
            ref={toggleRef as any}
            data-testid="shareholder-search-toggle-test-id"
            value={searchFieldValue}
            name={name}
            searchValue={searchFieldValue}
            as={ShareholderToggle}
            withoutToggleChevron
            withCustomInputClick
            isMenuOpen={isMenuOpen}
            searchPlaceholder={placeholder}
            handleClear={clearValueData}
            onSearchChange={handleSearch}
            className={classes["toggle"]}
            onBlur={handleBlur}
            onFocus={handleFocus}
            loading={isLoading}
            error={error}
            isTouched={isTouched}
            isDisabled={isDisabled}
          />
        </div>

        {hideEmptyResultsMenu && !filteredOptions.length ? null : (
          <ShareholderSearchDropdownMenu
            options={filteredOptions}
            searchValue={canShowResults ? debouncedSearchValue : ""}
            handleSelect={handleSelect}
          />
        )}
      </BDropdown>

      <ShareholderSearchModal
        show={matchShareholderModalOpen}
        handleClose={() => {
          setMatchShareholderModalOpen(false);
        }}
      />
    </>
  );
};

export default ShareholderSearchV2;
