import { FC } from "react";
import BDropdown from "react-bootstrap/Dropdown";
import classNames from "classnames";
import { isEmpty } from "ramda";

import { Ui } from "common/components/atoms/Typography";
import { ShareDetailsFormValues } from "common/components/molecules/ReceiverDetails/ReceiverDetails.types";
import { createTranslation, TranslationNS } from "translation";

import DropdownClasses from "../../atoms/Dropdown/Dropdown.module.scss";
import ShareholderSearchDropdowItem, { ShareholderSearchDropdowItemProps } from "./ShareholderSearch.item";
import classes from "./ShareholderSearch.module.scss";

const t = createTranslation(TranslationNS.common, "molecules.shareholderSearch");

const ShareholderSearchDropdownMenu: FC<
  Omit<ShareholderSearchDropdowItemProps, "option" | "index"> & {
    options: ShareDetailsFormValues[];
    isSmallFont?: boolean;
    notShowEmptyResults?: boolean;
  }
> = ({ isSmallFont, searchValue, options = [], notShowEmptyResults, handleSelect }) => {
  return (
    <BDropdown.Menu className={classNames(DropdownClasses["menu"], classes["menu-container"])}>
      {!isEmpty(options) ? (
        options.map((option: ShareDetailsFormValues, i: number) => (
          <ShareholderSearchDropdowItem
            key={`Dropdown key for single item is: ${option}-${i}`}
            index={i}
            option={option}
            isSmallComponentUsage={isSmallFont}
            searchValue={searchValue}
            handleSelect={handleSelect}
          />
        ))
      ) : notShowEmptyResults ? null : searchValue?.trim() ? (
        <div className={classes["empty"]}>
          <Ui.m
            style={{
              fontSize: isSmallFont ? 14 : 16,
            }}
          >
            {t.el("empty", {
              components: [<strong key={1} />],
            })}
          </Ui.m>
        </div>
      ) : null}
    </BDropdown.Menu>
  );
};

export default ShareholderSearchDropdownMenu;
