import { FC, useCallback, useMemo } from "react";
import AccordionBody from "react-bootstrap/AccordionBody";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "ramda";

import { getEMPath } from "app/Router/RouterHelper";
import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, P, Ui } from "common/components/atoms";
import PieChart, { PieChartProps } from "common/components/atoms/Charts/Pie.chart";
import { PoolStatuses } from "common/enums/enum";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { CompanyBuildingIcon, InformationCircleIcon, PlusIcon, PoolsIcon } from "common/icons/svg";
import Possibilities from "common/shareClass/components/Possibilities/Possibilities";
import { scssVariables } from "common/utils/constants";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { useStoreState } from "store/store";
import { PoolType } from "store/types";
import { createTranslation, TranslationNS } from "translation";

import ProgramCard from "./components/ProgramCard/ProgramCard";
import classes from "./PoolBody.module.scss";

const [t, tCommon] = [
  createTranslation(TranslationNS.pages, "company.poolsAndPrograms.poolItem"),
  createTranslation(TranslationNS.common, "noAccess"),
];

export type PoolBodyProps = {
  pool: PoolType;
};

const PoolBody: FC<PoolBodyProps> = ({ pool }) => {
  const navigate = useNavigate();
  const fNumber = useFormatNumbers();

  const approvalOptions = useStoreState((state) => state.common.dropdowns)?.approvalBodies;
  const { hasFullAccess } = useFeatures(FEATURES.poolsAndPrograms);

  const isDraftedPool = pool.status === PoolStatuses.draft;
  const selectedApprovalOption = approvalOptions?.find((el) => el.id === pool.approvalBodyId);

  const pieChartData = useMemo<PieChartProps["data"]>(() => {
    return [
      {
        id: t("shares.inPrograms"),
        value: pool.numberOfShares - pool.leftToDistributeShares,
        color: scssVariables.positive700,
      },
      {
        id: t("shares.left"),
        value: pool.leftToDistributeShares,
        color: scssVariables.element1,
      },
    ];
  }, [pool]);

  const handleAddProgramClick = useCallback(() => {
    navigate(getEMPath(["createProgram", "main"]), { state: { poolId: pool.id } });
  }, [navigate, pool.id]);

  return (
    <AccordionBody className={classes["wrap"]}>
      <div className={classes["content"]}>
        <Row className="d-flex justify-content-between">
          <Col sm={12} lg={6} className="d-flex flex-column" style={{ paddingRight: 8 }}>
            <div className="d-flex align-items-center">
              <PoolsIcon
                strokeWidth={0.7}
                width={56}
                height={56}
                style={{ marginLeft: -8 }}
                color={scssVariables.foregroundLow}
              />

              <div className="ms-2">
                <Ui.xl bold style={{ fontSize: 28 }}>
                  {fNumber(pool.numberOfShares, "amount")}
                </Ui.xl>
                <Ui.s style={{ color: scssVariables.foregroundMedium }}>{t("sharesInPool")}</Ui.s>
              </div>
            </div>

            <div className={classes["separator"]} />

            <div className="d-flex flex-column" style={{ margin: "auto 0" }}>
              <div className={classes["pie-chart-wrap"]}>
                <div className={classes["pie-chart"]}>
                  <PieChart isInteractive={false} height={180} data={pieChartData} />
                </div>

                <div className={classes["shares-legend"]}>
                  {pieChartData.map((item, index) => (
                    <div key={item.id} className={classes["shares-legend__item"]}>
                      <div className={classes["shares-legend__item__value"]}>{fNumber(item.value, "amount")}</div>

                      <div className={classes["shares-legend__item__description"]}>
                        <span
                          style={{ backgroundColor: item.color, borderColor: index === 0 ? item.color : undefined }}
                        />
                        {item.id}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Col>

          <Col sm={12} lg={6} style={{ paddingLeft: 8 }}>
            <div className="d-flex align-items-center">
              <CompanyBuildingIcon
                width={56}
                height={56}
                strokeWidth={0.7}
                style={{ padding: 7 }}
                color={scssVariables.foregroundLow}
              />

              <div className="ms-2">
                <Ui.xl bold style={{ fontSize: 28 }}>
                  {pool?.totalPercentage}%
                </Ui.xl>
                <Ui.s style={{ color: scssVariables.foregroundMedium }}>{t("allIssuedCompanyShares")}</Ui.s>
              </div>
            </div>

            <div className={classes["separator"]} />

            <div className={classes["pool-info-wrap"]}>
              <Possibilities
                hasDividends={pool.hasDividends}
                hasPreferences={pool.hasPreferences}
                hasVotingRights={pool.hasVotingRights}
              />

              <Row className={classes["additional-data-row"]}>
                <div className="d-flex">
                  <Ui.s bold style={{ flexBasis: "40%" }}>
                    {t("shareClass")}
                  </Ui.s>
                  <Ui.s>{pool.shareClassName}</Ui.s>

                  <div />
                </div>

                <div className="d-flex">
                  <Ui.s bold style={{ flexBasis: "40%" }}>
                    {t("dateOfApproval")}
                  </Ui.s>
                  <div>
                    <Ui.s>{pool?.approvedAt ? transformDateToCommonDateFormat(pool?.approvedAt) : "-"}</Ui.s>
                    {selectedApprovalOption?.name ? <Ui.s>by {selectedApprovalOption?.name}</Ui.s> : null}
                  </div>
                  <div />
                </div>

                <div className="d-flex">
                  <Ui.s bold style={{ flexBasis: "40%" }}>
                    {t("source")}
                  </Ui.s>
                  <Ui.s>{pool.shareSourceTypeName}</Ui.s>
                </div>
              </Row>
            </div>
          </Col>
        </Row>

        {pool?.description ? (
          <P.s className="d-flex w-50" style={{ color: scssVariables.foregroundMedium }}>
            {pool.description}
          </P.s>
        ) : null}

        <hr className={classes["divider"]} />

        <div className={classes["programs-container"]}>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <Ui.l bold>{t("programsTitle")}</Ui.l>
              <Ui.xs style={{ color: scssVariables.foregroundLow }}>{t("programsSubtitle")}</Ui.xs>
            </div>

            <Button
              size="s"
              isDisabled={!hasFullAccess || isDraftedPool}
              tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
              tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
              onClick={handleAddProgramClick}
              iconLeft={<PlusIcon />}
            >
              {t("addNewProgram")}
            </Button>
          </div>

          {isDraftedPool ? (
            <div className="mt-3 d-flex align-items-center" style={{ color: scssVariables.foregroundLow }}>
              <InformationCircleIcon width={24} height={24} />
              <Ui.xs className="ms-1">{t("finishPoolToStartAddingPrograms")}</Ui.xs>
            </div>
          ) : (
            <div className="d-flex flex-wrap justify-content-between">
              {!isEmpty(pool.ownershipPrograms) ? (
                pool.ownershipPrograms.map((program) => (
                  <Col key={program.id} xs={12} sm={6} className="mt-2" style={{ flexBasis: "49%" }}>
                    <ProgramCard program={program} />
                  </Col>
                ))
              ) : (
                <div className="mt-3 d-flex align-items-center" style={{ color: scssVariables.foregroundLow }}>
                  <InformationCircleIcon width={24} height={24} />
                  <Ui.xs className="ms-1">{t("empty")}</Ui.xs>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </AccordionBody>
  );
};

export default PoolBody;
