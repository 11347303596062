import { FC, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { useFormikContext } from "formik";

import Divider from "common/components/atoms/Divider/Divider";
import Dropdown from "common/components/atoms/Dropdown/Dropdown";
import TextField from "common/components/atoms/TextField/TextField";
import { H } from "common/components/atoms/Typography";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { SearchStakeholderResult } from "../ShareholderSearchV2/types";
import { fields as f, initialOptionalReceiverDetailsFields } from "./fields";
import { ShareDetailsFormValues } from "./ReceiverDetails.types";
import SearchableCompanyNameField from "./SearcableCompanyNameField";

const t = createTranslation(TranslationNS.common, "molecules.sharedDetails.business");

type PropsTypes = {
  isSearchable?: boolean;
  optionalFields?: Array<f>;
  isNewStockOptions?: boolean;
  selectedSearchUser: SearchStakeholderResult | null;
  onChangeUser: (user: SearchStakeholderResult | null) => void;
};

const Company: FC<PropsTypes> = ({
  optionalFields = initialOptionalReceiverDetailsFields,
  isNewStockOptions,
  isSearchable,
  selectedSearchUser,
  onChangeUser,
}) => {
  const { values, errors, touched, handleChange, handleBlur } = useFormikContext<ShareDetailsFormValues>();

  const relationshipList = useStoreState((state) => state.common.dropdowns)?.relationshipTypes;
  const countries = useStoreState((state) => state.common.dropdowns)?.countries;

  const optionalFieldsValues = useMemo(() => {
    return optionalFields.reduce((acc, curr, _, array) => {
      acc[curr] = array.includes(curr);

      return acc;
    }, {} as { [key in f]: boolean });
  }, [optionalFields]);

  return (
    <div>
      <Row className="mb-4">
        <Col>
          <SearchableCompanyNameField
            isSearchable={isSearchable}
            selectedSearchUser={selectedSearchUser}
            onChangeUser={onChangeUser}
            isOptional={optionalFieldsValues?.companyName}
          />
        </Col>
        <Col>
          <TextField
            isOptional={optionalFieldsValues?.email}
            label={t("businessEmail")}
            value={values[f.email]}
            error={errors[f.email]}
            isTouched={touched[f.email]}
            name={f.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <TextField
            isOptional={optionalFieldsValues?.organizationNumber}
            label={t("organizationNumber")}
            value={values[f.organizationNumber]}
            error={errors[f.organizationNumber]}
            info={isNewStockOptions ? t("organizationNumberInfo") : undefined}
            isTouched={touched[f.organizationNumber]}
            name={f.organizationNumber}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>
        <Col>
          <Dropdown
            label={t("relation")}
            options={relationshipList || []}
            optionsIsObject
            selectedValue={values[f.relationshipTypeId]}
            error={errors[f.relationshipTypeId]}
            isTouched={touched[f.relationshipTypeId]}
            name={f.relationshipTypeId}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <TextField
            isOptional={optionalFieldsValues?.address}
            label={t("address")}
            placeholder={t("addressPlaceholder")}
            value={values[f.address]}
            error={errors[f.address]}
            isTouched={touched[f.address]}
            name={f.address}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <Dropdown
            isOptional={optionalFieldsValues?.countryId}
            isSearchable
            label={t("country")}
            placeholder={t("notSelected")}
            options={countries || []}
            optionsIsObject
            selectedValue={values[f.countryId]}
            error={errors[f.countryId]}
            isTouched={touched[f.countryId]}
            name={f.countryId}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>
        <Col />
      </Row>
      <Row>
        <Col>
          <TextField
            isOptional={optionalFieldsValues?.businessPostAddress}
            label={t("postAddress")}
            placeholder={t("postAddressPlaceholder")}
            value={values[f.businessPostAddress]}
            error={errors[f.businessPostAddress]}
            isTouched={touched[f.businessPostAddress]}
            name={f.businessPostAddress}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>
      </Row>
      <Divider />
      <H.xxs>{t("shareholderRepresentative")}</H.xxs>
      <Row className="mb-4 mt-4">
        <Col>
          <TextField
            isOptional={optionalFieldsValues?.firstName}
            label={t("firstName")}
            placeholder={t("firstName")}
            value={values[f.firstName]}
            error={errors[f.firstName]}
            isTouched={touched[f.firstName]}
            name={f.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>
        <Col>
          <TextField
            isOptional={optionalFieldsValues?.lastName}
            label={t("lastName")}
            placeholder={t("lastName")}
            value={values[f.lastName]}
            error={errors[f.lastName]}
            isTouched={touched[f.lastName]}
            name={f.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <TextField
            isOptional={optionalFieldsValues?.phoneNumber}
            label={t("phoneNumber")}
            placeholder={t("phoneNumber")}
            value={values[f.phoneNumber]}
            error={errors[f.phoneNumber]}
            isTouched={touched[f.phoneNumber]}
            name={f.phoneNumber}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>
        <Col />
      </Row>
    </div>
  );
};
export default Company;
