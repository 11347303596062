import { FC } from "react";

import RenderStakeholderFields from "../fields/RenderStakeholderFields/RenderStakeholderFields";
import { StakeholderFieldsParamsType } from "../Stakeholder.types";
import useCompany from "./useCompany";

type CompanyProps = {
  updatedFields?: StakeholderFieldsParamsType;
};

const Company: FC<CompanyProps> = ({ updatedFields }) => {
  const { topFields, otherFields } = useCompany(updatedFields);

  return <RenderStakeholderFields otherFields={otherFields} topFields={topFields} />;
};
export default Company;
