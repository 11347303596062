import { FC, useCallback, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { useFormikContext } from "formik";

import DatePicker from "common/components/atoms/DatePicker/DatePicker";
import Dropdown from "common/components/atoms/Dropdown/Dropdown";
import TextField from "common/components/atoms/TextField/TextField";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { SearchStakeholderResult } from "../ShareholderSearchV2/types";
import { fields as f, initialOptionalReceiverDetailsFields } from "./fields";
import { ShareDetailsFormValues } from "./ReceiverDetails.types";
import SearchableFirstNameField from "./SearchableFirstNameField";

const t = createTranslation(TranslationNS.common, "molecules.sharedDetails.personal");

type PropsTypes = {
  isSearchable?: boolean;
  optionalFields?: Array<f>;
  selectedSearchUser: SearchStakeholderResult | null;
  onChangeUser: (user: SearchStakeholderResult | null) => void;
  isDisabled?: boolean;
};

const Personal: FC<PropsTypes> = ({
  optionalFields = initialOptionalReceiverDetailsFields,
  isSearchable,
  selectedSearchUser,
  onChangeUser,
  isDisabled,
}) => {
  const { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    useFormikContext<ShareDetailsFormValues>();

  const relationshipList = useStoreState((state) => state.common.dropdowns)?.relationshipTypes;
  const countries = useStoreState((state) => state.common.dropdowns)?.countries;

  const optionalFieldsValues = useMemo(() => {
    return optionalFields.reduce((acc, curr, _, array) => {
      acc[curr] = array.includes(curr);

      return acc;
    }, {} as { [key in f]: boolean });
  }, [optionalFields]);

  const handleChangeBirthDate = useCallback(
    (date: string | Date) => {
      setFieldValue(f.dateOfBirth, date);
    },
    [setFieldValue]
  );

  return (
    <div>
      <Row className="mb-4">
        <Col>
          <SearchableFirstNameField
            isSearchable={isSearchable}
            selectedSearchUser={selectedSearchUser}
            onChangeUser={onChangeUser}
            isOptional={optionalFieldsValues?.firstName}
            isDisabled={isDisabled}
          />
        </Col>
        <Col>
          <TextField
            isOptional={optionalFieldsValues?.lastName}
            label={t("lastName")}
            value={values[f.lastName]}
            error={errors[f.lastName]}
            isTouched={touched[f.lastName]}
            name={f.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            isDisabled={isDisabled}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <TextField
            isOptional={optionalFieldsValues?.email}
            label={t("email")}
            value={values[f.email]}
            error={errors[f.email]}
            isTouched={touched[f.email]}
            name={f.email}
            onChange={handleChange}
            onBlur={handleBlur}
            isDisabled={isDisabled}
          />
        </Col>
        <Col>
          <DatePicker
            isClearable
            isOptional
            isDateOnlyString
            isDisabled={isDisabled}
            label={t("dateOfBirth")}
            date={values[f.dateOfBirth]}
            name={f.dateOfBirth}
            onChange={handleChangeBirthDate}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <TextField
            isOptional={optionalFieldsValues?.phoneNumber}
            label={t("phoneNumber")}
            value={values[f.phoneNumber]}
            error={errors[f.phoneNumber]}
            isTouched={touched[f.phoneNumber]}
            name={f.phoneNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            isDisabled={isDisabled}
          />
        </Col>
        <Col />
      </Row>
      <Row className="mb-4">
        <Col>
          <TextField
            isOptional
            label={t("address")}
            placeholder={t("addressPlaceholder")}
            value={values[f.address]}
            error={errors[f.address]}
            isTouched={touched[f.address]}
            name={f.address}
            onChange={handleChange}
            onBlur={handleBlur}
            isDisabled={isDisabled}
          />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <Dropdown
            isOptional
            isSearchable
            label={t("country")}
            placeholder={t("notSelected")}
            options={countries || []}
            optionsIsObject
            selectedValue={values[f.countryId]}
            error={errors[f.countryId]}
            isTouched={touched[f.countryId]}
            name={f.countryId}
            onChange={handleChange}
            onBlur={handleBlur}
            isDisabled={isDisabled}
          />
        </Col>
        <Col>
          <Dropdown
            label={t("relation")}
            options={relationshipList || []}
            optionsIsObject
            selectedValue={values[f.relationshipTypeId]}
            error={errors[f.relationshipTypeId]}
            isTouched={touched[f.relationshipTypeId]}
            name={f.relationshipTypeId}
            onChange={handleChange}
            onBlur={handleBlur}
            isDisabled={isDisabled}
          />
        </Col>
      </Row>
    </div>
  );
};
export default Personal;
