import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import { getPath } from "app/Router/RouterHelper";
import { ROUTER_V2 } from "app/Router/RouterV2.types";
import useDocumentTitleUpdate from "common/hooks/useDocumentTitleUpdate";
import OnboardCompanyBasic from "pages/onboard/company/company-information/CompanyInformation";

import GetStarted from "./get-started/GetStarted";
import ImportFounders from "./import-founders/ImportFounders";
import ImportShareholders from "./import-shareholders/ImportShareholders";
import ImportStakeholders from "./Import-stakeholders/ImportStakeholders";
import ImportTransaction from "./import-transaction/ImportTransaction";
import LocalCompanyStore from "./OnboardCompanyContext";
import CompanyOnboardSetup from "./setup/CompanyOnboardSetup";
import ShareClasses from "./shareClasses/OnboardCompanyShareClasses";

const OnboardCompany: FC = () => {
  useDocumentTitleUpdate("Company registration");

  return (
    <div className="position-relative w-100">
      <AnimatePresence>
        <Routes>
          <Route path={ROUTER_V2.onboard.company.setup} element={<CompanyOnboardSetup />} />
          <Route path={ROUTER_V2.onboard.company.getStarted} element={<GetStarted />} />
          <Route path={ROUTER_V2.onboard.company.companyInformation} element={<OnboardCompanyBasic />} />
          <Route path={ROUTER_V2.onboard.company.importStakeholders} element={<ImportStakeholders />} />
          <Route path={ROUTER_V2.onboard.company.importShareholders} element={<ImportShareholders />} />
          <Route path={ROUTER_V2.onboard.company.importTransaction} element={<ImportTransaction />} />
          <Route path={ROUTER_V2.onboard.company.importFounders} element={<ImportFounders />} />
          <Route path={ROUTER_V2.onboard.company.shareClasses} element={<ShareClasses />} />
          <Route path="*" element={<Navigate to={getPath(["onboard", "company", "setup"])} />} />
        </Routes>
      </AnimatePresence>
    </div>
  );
};

const OnboardCompanyRouter: FC = () => {
  return (
    <LocalCompanyStore.Provider>
      <OnboardCompany />
    </LocalCompanyStore.Provider>
  );
};

export default OnboardCompanyRouter;
