import { FC, SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useFormikContext } from "formik";
import { motion } from "framer-motion";
import { pick } from "ramda";

import { Button, NewAvatar, Tooltip, Ui } from "common/components/atoms";
import InputFeedback from "common/components/atoms/input-blocks/input-feedback/input-feedback";
import { DeleteIcon, EditIcon } from "common/icons/svg";

import ShowRequiredFields from "../components/ShowRequiredFields";
import StakeholderTooltip from "../components/StakeholderTooltip";
import { animationStakeholder } from "../fields/RenderStakeholderFields/RenderStakeholderFields";
import { initialEmptyValues, StakeholderFormValues, updatedStakeholderFields } from "../Stakeholder.types";
import classes from "./SelectedStakeholder.module.scss";
import UpdateStakeholderForm from "./UpdateStakeholderForm";

type SelectedStakeholderProps = {
  isDisabled?: boolean;
  allowUpdate?: boolean;
};
const SelectedStakeholder: FC<SelectedStakeholderProps> = ({ isDisabled, allowUpdate }) => {
  const { values, errors, setValues, setTouched, touched, validateForm } = useFormikContext<StakeholderFormValues>();

  const [editMode, setEditMode] = useState(false);
  const [valuesBeforeEdit, setValuesBeforeEdit] = useState<StakeholderFormValues | null>(null);

  const handleEdit = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      setEditMode(true);
      const pickedValues = pick(updatedStakeholderFields, values);
      setValuesBeforeEdit(pickedValues);
    },
    [values]
  );

  const handleCancel = useCallback(() => {
    setEditMode(false);
    setValues({ ...values, ...valuesBeforeEdit });
  }, [setValues, values, valuesBeforeEdit]);

  const handleCloseAfterSafe = useCallback(() => {
    setEditMode(false);
    setValuesBeforeEdit(null);
  }, []);

  const handleClear = useCallback(() => {
    setValues({ ...values, ...initialEmptyValues });
    setTouched({
      ...touched,
      ...Object.fromEntries(Object.entries(initialEmptyValues).map(([key, _]) => [key, false])),
    });
  }, [setTouched, setValues, touched, values]);

  const stakeholderError = useMemo(() => {
    return Object.keys(pick(updatedStakeholderFields, errors)).length > 0;
  }, [errors]);

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  return (
    <div>
      <div className={classNames(classes.wrap, { "is-invalid": stakeholderError && allowUpdate })}>
        <motion.div initial="open" variants={animationStakeholder} animate={!editMode ? "open" : "close"}>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <NewAvatar
                className="me-2"
                size="m"
                companyName={values.companyName}
                lastName={values.lastName}
                firstName={values.firstName}
                company={values.isCompanyOwned}
              />
              <div>
                <Ui.xl bold>
                  {values.isCompanyOwned ? values.companyName : `${values.firstName} ${values.lastName}`}
                  <Ui.xl tag="span" color="foregroundLow">
                    <StakeholderTooltip stakeholder={values} />
                  </Ui.xl>
                </Ui.xl>
                {values.isCompanyOwned && (
                  <Ui.s color="foregroundLow">
                    {`${values.firstName} ${values.lastName} ${values.email ? " • " + values.email : ""}`}
                  </Ui.s>
                )}
                {!values.isCompanyOwned && values.email && <Ui.s color="foregroundLow">{values.email}</Ui.s>}
              </div>
            </div>
            {!isDisabled && (
              <div className="d-flex">
                {allowUpdate && (
                  <Tooltip popupContent="Update stakeholder information">
                    <div className="me-3">
                      <Button size="s" isOnlyIcon variant="secondary" onClick={handleEdit}>
                        <EditIcon />
                      </Button>
                    </div>
                  </Tooltip>
                )}
                <Tooltip popupContent="Remove stakeholder">
                  <div>
                    <Button size="s" isOnlyIcon variant="secondary" onClick={handleClear}>
                      <DeleteIcon />
                    </Button>
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
        </motion.div>
        {allowUpdate && (
          <UpdateStakeholderForm
            show={editMode}
            handleCancel={handleCancel}
            handleCloseAfterSafe={handleCloseAfterSafe}
            handleClear={handleClear}
            stakeholderName={
              valuesBeforeEdit?.isCompanyOwned
                ? `${valuesBeforeEdit.companyName} (${valuesBeforeEdit?.firstName} ${valuesBeforeEdit?.lastName})`
                : `${valuesBeforeEdit?.firstName} ${valuesBeforeEdit?.lastName}`
            }
          />
        )}
      </div>
      {stakeholderError && allowUpdate && (
        <InputFeedback
          isTouched
          error={
            <>
              Required fields missing.
              {!editMode && (
                <>
                  Update by clicking edit button or{" "}
                  <a className={classNames(classes.link, classes.error)} href="#" onClick={handleEdit}>
                    here
                  </a>
                  .
                </>
              )}
            </>
          }
        />
      )}
      {!allowUpdate && <ShowRequiredFields />}
    </div>
  );
};

export default SelectedStakeholder;
