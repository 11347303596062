import { useContext } from "react";
import { useFormikContext } from "formik";

import { ShareDetailsFormValues } from "../../ReceiverDetails/ReceiverDetails.types";
import Company from "../Company/Company";
import StakeholderCompanyField from "../fields/StakeholderIsCompanyField";
import Personal from "../Personal/Personal";
import { StakeholderSectionContext } from "../StakeholderSection";

const NewStakeholder = () => {
  const { values } = useFormikContext<ShareDetailsFormValues>();

  const updatedFields = useContext(StakeholderSectionContext).updatedFields;
  return (
    <div>
      <StakeholderCompanyField className="mb-4" />
      {values.isCompanyOwned ? <Company updatedFields={updatedFields} /> : <Personal updatedFields={updatedFields} />}
    </div>
  );
};

export default NewStakeholder;
