import * as Yup from "yup";

import { TFunction } from "translation/helpers";

import { SplitFields } from "../../split/form-fields";

export const splitDetailsValidationSchema = (tFunction: TFunction) => ({
  [SplitFields.multiplier]: Yup.number()
    .integer(tFunction("integer"))
    .min(2, tFunction("minNumber", { number: 2 }))
    .required(tFunction("required")),
});
