import { FC, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as R from "ramda";

import { getPath } from "app/Router/RouterHelper";
import { ReceiveRouteType, ROUTER_V2 } from "app/Router/RouterV2.types";
import { InvitedUser } from "store/InvitedUserModel";
import { useStoreActions, useStoreState } from "store/store";

import Onboard from "../onboard/Onboard";
import OnboardUserTerms from "../onboard/user/terms/OnboardUserTerms";

const Invitations: FC = () => {
  const navigate = useNavigate();
  const { invitationType, entityId, approvalKey } = useParams<{
    invitationType: string;
    entityId: string;
    approvalKey: string;
  }>();

  const { user } = useStoreState((state) => state.account);
  const { accountRequestReceived } = useStoreState((state) => state.status);
  const { localCacheChecked, isAuthenticated } = useStoreState((state) => state.authentication);
  const { setInvitedUser } = useStoreActions((actions) => actions.invitedUser);
  const { setRedirectUrl } = useStoreActions((actions) => actions.app);

  const allowedUrls = useMemo(() => {
    return R.invertObj(ROUTER_V2.receive);
  }, []);

  const receiveUrl = useMemo(() => {
    if (invitationType && allowedUrls[invitationType]) {
      return getPath(["receive", allowedUrls[invitationType] as ReceiveRouteType], { entityId: entityId });
    }
    return "";
  }, [allowedUrls, entityId, invitationType]);

  useEffect(() => {
    if (!receiveUrl) {
      setRedirectUrl("");
      navigate("/");
      return;
    }
    if (!localCacheChecked || (isAuthenticated && !accountRequestReceived)) {
      return;
    }

    const invitationExpiry = new Date(Date.now() + 2 * (60 * 60 * 1000)).toISOString();

    const invitedUserData: InvitedUser = {
      entityId,
      approvalKey: approvalKey ? approvalKey : "",
      invitationExpiry,
      invitationType: allowedUrls[invitationType as string] as ReceiveRouteType,
    };

    setInvitedUser(invitedUserData);

    if (isAuthenticated && !R.isNil(user) && user.isOnboarded) {
      // important to reset redirectUrl here, otherwise the user will be redirected to the
      setRedirectUrl("");
      navigate(receiveUrl);
      return;
    }
  }, [
    accountRequestReceived,
    approvalKey,
    entityId,
    navigate,
    invitationType,
    isAuthenticated,
    localCacheChecked,
    receiveUrl,
    setInvitedUser,
    setRedirectUrl,
    user,
    allowedUrls,
  ]);

  if (isAuthenticated && !R.isNil(user) && user.isOnboarded) {
    return null;
  }

  return (
    <Onboard>
      <div className="d-flex w-100 justify-content-center">
        <OnboardUserTerms nextStep={receiveUrl} />
      </div>
    </Onboard>
  );
};

export default Invitations;
