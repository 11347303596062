import { ChangeEventHandler, FC, useCallback, useEffect, useMemo, useState } from "react";
import { useFormikContext } from "formik";
import * as R from "ramda";

import { createTranslation, TranslationNS } from "translation";

import ChecksGroup from "../../atoms/Checks/ChecksGroup";
import ShareholderSearchV2 from "../ShareholderSearchV2/ShareholderSearchV2";
import { SearchStakeholderResult } from "../ShareholderSearchV2/types";
import Company from "./Company";
import { fields as f } from "./fields";
import Personal from "./Personal";
import { ShareDetailsFormValues } from "./ReceiverDetails.types";

const t = createTranslation(TranslationNS.common, "molecules.sharedDetails");

type CustomTitles = {
  owningCustomTitle?: string;
  personCustomTitle?: string;
  companyCustomTitle?: string;
};

type ReceiverDetailsProps = {
  optionalFields?: Array<f>;
  isNewStockOptions?: boolean; //TEMP value required to hide info messages for org number
  isSearchable?: boolean;
  customTitles?: CustomTitles;
  isDisabled?: boolean;
};

const ReceiverDetailsV2: FC<ReceiverDetailsProps> = ({
  isSearchable,
  optionalFields,
  isNewStockOptions,
  customTitles,
  isDisabled,
}) => {
  const { values, setFieldValue } = useFormikContext<ShareDetailsFormValues>();

  const receiverValues = useMemo(
    () => ({
      stakeholderId: values.stakeholderId || 0,
      [f.firstName]: values.firstName || "",
      [f.lastName]: values.lastName || "",
      [f.email]: values.email || "",
      [f.countryId]: values.countryId || undefined,
      [f.phoneNumber]: values.phoneNumber || "",
      [f.address]: values.address || "",
      [f.dateOfBirth]: values?.dateOfBirth?.toString() || undefined,
      [f.relationshipTypeId]: values.relationshipTypeId || 1,
      [f.isCompanyOwned]: values.isCompanyOwned || false,
      [f.companyName]: values.companyName || "",
      [f.organizationNumber]: values.organizationNumber || "",
      [f.businessPostAddress]: values.businessPostAddress || "",
    }),
    [values]
  );

  const [receiver, setReceiver] = useState<SearchStakeholderResult | null>(receiverValues);
  const [userSetBySearchExisting, setUserSetBySearchExisting] = useState<boolean>();

  useEffect(() => {
    setReceiver(receiverValues);
  }, [receiverValues]);

  const handleSetReceiver = useCallback(
    async (receiver: SearchStakeholderResult | null) => {
      if (receiver) {
        setFieldValue("stakeholderId", receiver.stakeholderId || 0);
        setFieldValue(f.lastName, receiver.lastName || "");
        setFieldValue(f.dateOfBirth, receiver.dateOfBirth || undefined);
        setFieldValue(f.email, receiver.email || "");
        setFieldValue(f.address, receiver.address || "");
        setFieldValue(f.phoneNumber, receiver.phoneNumber || "");
        setFieldValue(f.countryId, receiver.countryId || undefined);
        setFieldValue(f.isCompanyOwned, receiver.isCompanyOwned || false);
        setFieldValue(f.organizationNumber, receiver.organizationNumber || "");
        setFieldValue(f.businessPostAddress, receiver.businessPostAddress || "");
        setFieldValue(f.relationshipTypeId, receiver.relationshipTypeId || 1);
        await setFieldValue(f.companyName, receiver.companyName || "");
        await setFieldValue(f.firstName, receiver.firstName || "");
      } else {
        setFieldValue("stakeholderId", 0);
        setFieldValue(f.lastName, "");
        setFieldValue(f.dateOfBirth, undefined);
        setFieldValue(f.email, "");
        setFieldValue(f.address, "");
        setFieldValue(f.phoneNumber, "");
        setFieldValue(f.countryId, 0);
        setFieldValue(f.isCompanyOwned, false);
        setFieldValue(f.organizationNumber, "");
        setFieldValue(f.businessPostAddress, "");
        setFieldValue(f.relationshipTypeId, 1);
        await setFieldValue(f.companyName, "");
        await setFieldValue(f.firstName, "");
      }
    },
    [setFieldValue]
  );

  const handleSetReceiverBySearchExisting = useCallback(
    (receiver: SearchStakeholderResult | null) => {
      setUserSetBySearchExisting(true);
      handleSetReceiver(receiver);
    },
    [handleSetReceiver]
  );
  const handleSetReceiverByOtherMethod = useCallback(
    (receiver: SearchStakeholderResult | null) => {
      setUserSetBySearchExisting(false);
      handleSetReceiver(receiver);
    },
    [handleSetReceiver]
  );

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      setFieldValue(e.target.name, e.target.value === "company");
    },
    [setFieldValue]
  );

  return (
    <div data-testid="share-details-from-id">
      <ShareholderSearchV2
        onSelect={handleSetReceiverBySearchExisting}
        selectedUser={userSetBySearchExisting ? receiver : R.omit(["firstName", "lastName"], receiver)}
        isDisabled={isDisabled}
        className="my-4"
      />

      <ChecksGroup
        className="mb-4"
        isDisabled={isDisabled}
        label={customTitles?.owningCustomTitle || t("howShouldTheSharesBeOwned")}
      >
        <ChecksGroup.Check
          isNoFocus
          type="radio"
          label={customTitles?.personCustomTitle || t("ownedPersonally")}
          checked={!values[f.isCompanyOwned]}
          name={f.isCompanyOwned}
          value={"person"}
          onChange={handleChange}
          disabled={isDisabled}
        />
        <ChecksGroup.Check
          isNoFocus
          type="radio"
          label={customTitles?.companyCustomTitle || t("throughTheCompany")}
          checked={values[f.isCompanyOwned]}
          name={f.isCompanyOwned}
          value={"company"}
          onChange={handleChange}
          disabled={isDisabled}
        />
      </ChecksGroup>
      {!values[f.isCompanyOwned] ? (
        <Personal
          isSearchable={isSearchable}
          optionalFields={optionalFields}
          selectedSearchUser={receiver}
          onChangeUser={handleSetReceiverByOtherMethod}
          isDisabled={isDisabled}
        />
      ) : (
        <Company
          isSearchable={isSearchable}
          optionalFields={optionalFields}
          isNewStockOptions={isNewStockOptions}
          selectedSearchUser={receiver}
          onChangeUser={handleSetReceiverByOtherMethod}
        />
      )}
    </div>
  );
};

export default ReceiverDetailsV2;
