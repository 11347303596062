import { useMemo } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { FormikHelpers, FormikValues } from "formik";
import * as R from "ramda";
import * as Yup from "yup";

import { ShareDetailsFormValues } from "common/components/molecules/ReceiverDetails/ReceiverDetails.types";
import { RelationshipTypesEnum } from "common/enums/enum";
import { notify } from "common/utils/notify/notifyFunction";
import { EMAIL_VALIDATION_PATTERN } from "common/utils/validators";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import IssueEquityContext from "../../IssueEquityContext";

const t = createTranslation(TranslationNS.pages, "company.issueEquity.warrants.editPanel");
const tValidation = createTranslation("validation");

const useWarrantsEditPanel = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const { shareClassesCompany } = useStoreState((state) => state.shareClassModel);
  const { isWarrantsEditPanelOpen, warrantDetails } = IssueEquityContext.useStoreState((state) => state);
  const { setIsWarrantsEditPanelOpen, setWarrantDetails, getIssueEquityThunk } = IssueEquityContext.useStoreActions(
    (actions) => actions
  );

  const initialValues = useMemo(() => {
    const selectedShareClass = warrantDetails?.shareClassId
      ? shareClassesCompany.find((el) => el.id === warrantDetails?.shareClassId)
      : null;

    return {
      shares: R.defaultTo(0, warrantDetails?.numberOfShares),
      documents: [],
      warrantPrice: R.defaultTo(0, warrantDetails?.warrantPrice),
      strikePrice: R.defaultTo(0, warrantDetails?.strikePrice),
      numberOfShares: R.defaultTo(0, warrantDetails?.numberOfShares),
      expirationDate: R.defaultTo("", warrantDetails?.expirationDate),
      shareClassComment: R.defaultTo("", warrantDetails?.shareClassComment),
      isCompanyOwned: R.defaultTo(false, warrantDetails?.isCompanyOwned),
      notablePointsFromAgreement: R.defaultTo("", warrantDetails?.notablePoints),
      documentStatus: R.defaultTo(undefined, warrantDetails?.documentStatusId),
      shareClass: selectedShareClass,
      isNeedSignatureChecked: R.defaultTo(false, warrantDetails?.documentsNeedsSignature),
      isNeedEmailInvitationChecked: R.defaultTo(false, warrantDetails?.sendInvitationEmail),
      isShareClassAgreedChecked: R.isNil(warrantDetails?.shareClassComment),
      firstName: R.defaultTo("", warrantDetails?.firstName),
      lastName: R.defaultTo("", warrantDetails?.lastName),
      email: R.defaultTo("", warrantDetails?.email),
      dateOfBirth: R.defaultTo("", warrantDetails?.dateOfBirth),
      phoneNumber: R.defaultTo("", warrantDetails?.phoneNumber),
      address: R.defaultTo("", warrantDetails?.address),
      countryId: R.defaultTo(undefined, warrantDetails?.countryId),
      companyName: R.defaultTo("", warrantDetails?.companyName),
      organizationNumber: R.defaultTo("", warrantDetails?.organisationNumber),
      relationshipTypeId: R.defaultTo(RelationshipTypesEnum.EMPLOYEE, warrantDetails?.relationshipTypeId),
      businessPostAddress: R.defaultTo("", warrantDetails?.businessPostAddress),
    };
  }, [warrantDetails, shareClassesCompany]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string().required(tValidation("required")),
        lastName: Yup.string().required(tValidation("required")),
        companyName: Yup.string().when("isCompanyOwned", {
          is: true,
          then: Yup.string().required(tValidation("required")),
        }),
        numberOfShares: Yup.number()
          .integer(tValidation("integer"))
          .min(
            1,
            tValidation("minNumber", {
              number: 1,
            })
          )
          .required(tValidation("required")),
        email: Yup.string()
          .matches(EMAIL_VALIDATION_PATTERN, tValidation("invalidEmail"))
          .when("isNeedEmailInvitationChecked", {
            is: true,
            then: Yup.string().required(tValidation("required")),
          }),
        relationshipTypeId: Yup.string().required(tValidation("requiredSection")),
        documentStatus: Yup.number().required(tValidation("requiredSection")),
        warrantPrice: Yup.number()
          .nullable()
          .min(0, tValidation("minNumber", { number: 0 })),
        strikePrice: Yup.number()
          .min(
            0.0001,
            tValidation("minNumberMore", {
              number: 0.0001,
            })
          )
          .required(tValidation("required")),
      }),
    []
  );

  const submitHandler = async (e?: FormikValues["onSubmit"], actions?: FormikHelpers<any>) => {
    try {
      const formValues = e as typeof initialValues & ShareDetailsFormValues;
      const formData = new FormData();

      formData.append("CompanyId", String(companyId));
      formData.append("IsCompanyOwned", String(Boolean(formValues.isCompanyOwned)));
      formData.append("DocumentsNeedsSignature", String(formValues.isNeedSignatureChecked));
      formData.append("SendInvitationEmail", String(formValues.isNeedEmailInvitationChecked));
      formData.append("DocumentStatusId", String(formValues.documentStatus));

      if (formValues.firstName) {
        formData.append("FirstName", String(formValues.firstName));
      }

      if (formValues.lastName) {
        formData.append("LastName", String(formValues.lastName));
      }

      if (!R.isEmpty(formValues.documents)) {
        formValues.documents.forEach((el) => formData.append("FilesData.Files", el));
      }

      if (!R.isNil(warrantDetails?.filesData?.oldFileIds)) {
        warrantDetails?.filesData?.oldFileIds.forEach((el) => formData.append("FilesData.OldFileIds", el));
      }

      if (formValues.email) {
        formData.append("Email", formValues.email);
      }

      if (formValues.dateOfBirth) {
        formData.append("DateOfBirth", String(formValues.dateOfBirth));
      }

      if (formValues.address) {
        formData.append("Address", formValues.address);
      }

      if (formValues.countryId) {
        formData.append("CountryId", String(formValues.countryId));
      }

      if (formValues.companyName?.trim()) {
        formData.append("CompanyName", formValues.companyName);
      }

      if (formValues.businessEmail) {
        formData.append("BusinessEmail", formValues.businessEmail);
      }

      if (formValues.businessPostAddress) {
        formData.append("BusinessPostAddress", formValues.businessPostAddress);
      }

      if (formValues.organizationNumber?.trim()) {
        formData.append("OrganizationNumber", formValues.organizationNumber);
      }

      if (formValues.shareClass && formValues.isShareClassAgreedChecked) {
        formData.append("ShareClassId", String(formValues.shareClass.id));
      }

      if (formValues.shareClassComment?.trim() && !formValues.isShareClassAgreedChecked) {
        formData.append("ShareClassComment", String(formValues.shareClassComment));
      }

      if (formValues.relationshipTypeId) {
        formData.append("RelationshipTypeId", String(formValues.relationshipTypeId));
      }

      if (formValues.numberOfShares) {
        formData.append("NumberOfShares", String(formValues.numberOfShares));
      }

      if (formValues.shares) {
        formData.append("SharePercentage", String(formValues.shares));
      }

      if (formValues.expirationDate) {
        formData.append("ExpirationDate", String(formValues.expirationDate));
      }

      if (formValues.warrantPrice) {
        formData.append("WarrantPrice", String(formValues.warrantPrice));
      }

      if (formValues.strikePrice) {
        formData.append("StrikePrice", String(formValues.strikePrice));
      }

      if (formValues.notablePointsFromAgreement) {
        formData.append("NotablePoints", String(formValues.notablePointsFromAgreement));
      }

      if (formValues.phoneNumber) {
        formData.append("PhoneNumber", String(formValues.phoneNumber));
      }

      if (!R.isNil(warrantDetails)) {
        formData.append("WarrantId", String(warrantDetails?.warrantId));
      }

      if (formValues.stakeholderId) {
        formData.append("StakeholderId", String(formValues.stakeholderId));
      }

      const requestURL = R.isNil(warrantDetails)
        ? "/api/equity-management/issue-equity/warrant"
        : "/api/equity-management/issue-equity/warrant/edit";

      const request = await axios.post(requestURL, formData);

      if (request.status === 200) {
        notify(
          !R.isNil(warrantDetails)
            ? t("successUpdateMessage")
            : formValues.isNeedEmailInvitationChecked
            ? t("successMessage")
            : t("successWithoutEmail"),
          true,
          "success",
          5000,
          false,
          "top-center"
        );
        await getIssueEquityThunk(Number(companyId));
        setIsWarrantsEditPanelOpen({ open: false });
        setWarrantDetails(null);
        actions?.resetForm();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const closePanel = () => {
    setIsWarrantsEditPanelOpen({ open: false });
    setWarrantDetails(null);
  };

  return {
    initialValues,
    warrantDetails,
    validationSchema,
    shareClassesCompany,
    isWarrantsEditPanelOpen,
    closePanel,
    submitHandler,
  };
};

export default useWarrantsEditPanel;
