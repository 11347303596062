import { FC, MouseEventHandler, useCallback, useEffect, useMemo, useState } from "react";
import BDropdown from "react-bootstrap/Dropdown";
import Highlighter from "react-highlight-words";
import { isEmpty } from "ramda";

import { CompanyBuildingIcon, UserIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";

import classes from "./ShareholderSearch.module.scss";
import { SearchStakeholderResult } from "./types";

export type ShareholderSearchDropdownItemProps = {
  index: number;
  searchValue?: string;
  option: SearchStakeholderResult;
  isSmallComponentUsage?: boolean;
  handleSelect: (selectedValue: SearchStakeholderResult) => void;
};

const ShareholderSearchDropdownItem: FC<ShareholderSearchDropdownItemProps> = ({
  option,
  index,
  searchValue,
  isSmallComponentUsage,
  handleSelect,
}) => {
  const [itemType, setItemType] = useState<"user" | "company" | "organizationNumber" | null>();

  const highlightedItemValue = useMemo(() => {
    if (itemType === "organizationNumber" && option.isCompanyOwned) {
      return `${option.organizationNumber} (${option.companyName})`;
    }

    if (itemType === "user") {
      return `${option.firstName} ${option.lastName}`;
    }

    if (itemType === "company") {
      return `${option.companyName} (${option.firstName} ${option.lastName})`;
    }

    return "";
  }, [itemType, option]);

  useEffect(() => {
    if (!isNaN(Number(searchValue)) && option.isCompanyOwned && !isEmpty(searchValue)) {
      setItemType("organizationNumber");

      return;
    }

    if (option.isCompanyOwned) {
      setItemType("company");

      return;
    }
    if (!option.isCompanyOwned) {
      setItemType("user");

      return;
    }
  }, [searchValue, option.isCompanyOwned, setItemType]);

  const handleSelectOption: MouseEventHandler<HTMLElement> = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      handleSelect(option);
    },
    [handleSelect, option]
  );

  return (
    <BDropdown.Item
      eventKey={index}
      className={classes["menu-item"]}
      data-testid="shareholder-item-test-id"
      onClick={handleSelectOption}
    >
      <Highlighter
        autoEscape={true}
        data-testid="shareholder-item-test-id"
        searchWords={[searchValue as string]}
        highlightClassName={classes["highlighted-word"]}
        textToHighlight={highlightedItemValue}
        unhighlightClassName="shareholder-item-test-id"
      />

      {!isSmallComponentUsage ? (
        option.isCompanyOwned ? (
          <CompanyBuildingIcon color={scssVariables.foregroundLow} />
        ) : (
          <UserIcon strokeWidth={1} width={24} height={24} color={scssVariables.foregroundLow} />
        )
      ) : null}
    </BDropdown.Item>
  );
};

export default ShareholderSearchDropdownItem;
