import { FC, useCallback } from "react";
import { useFormikContext } from "formik";

import { Check, H } from "common/components/atoms";
import ReceiverDetails from "common/components/molecules/ReceiverDetails/ReceiverDetails";
import ShareholderSearch from "common/components/molecules/ShareholderSearch/ShareholderSearch";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import { newPlanFieldsNames as f } from "common/plan/planFormFields";
import { createTranslation, TranslationNS } from "translation";

import { OwnershipPlanForm } from "../../CreateGrantOneOffPlan";
import classes from "./PlanReciver.module.scss";

const t = createTranslation(TranslationNS.pages, "company.grantOneOffRSAPlan.details");

const PlanReceiver: FC = () => {
  const { values, handleChange, handleSubmit } = useFormikContext<OwnershipPlanForm>();

  const submitHandler = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  return (
    <WizardContent.Content step={1}>
      <H.xs>{t("title")}</H.xs>

      <div className="my-5">
        <ShareholderSearch />
      </div>

      <ReceiverDetails isSearchable isNewStockOptions />

      <Check
        className={`${classes["checkbox"]} my-3`}
        label={t("existingPlan")}
        checked={values[f.existingPlan]}
        name={f.existingPlan}
        onChange={handleChange}
      />

      <div className="d-flex mt-8">
        <WizardContent.Controls.ContinueButton className="ms-auto" onClick={submitHandler} />
      </div>
    </WizardContent.Content>
  );
};

export default PlanReceiver;
