import { ChangeEvent, forwardRef, JSX, MouseEvent, MouseEventHandler, useCallback } from "react";
import { Spinner } from "react-bootstrap";
import cn from "classnames";

import TextField, { TextFieldProps } from "common/components/atoms/TextField/TextField";
import { CloseIcon, SearchIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";

import classes from "../../atoms/Dropdown/Dropdown.module.scss";

type ToggleProps = {
  children: JSX.Element;
  onClick: (e: MouseEvent) => void;
  isSearchable: boolean;
  isMenuOpen: boolean;
  searchPlaceholder?: string;
  searchValue?: string;
  withoutToggleChevron?: boolean;
  onSearchChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  withCustomInputClick?: boolean;
  handleClear?: () => void;
  loading?: boolean;
};

type PropsTypes = TextFieldProps & ToggleProps;

const ShareholderToggle = forwardRef<TextFieldProps, PropsTypes>(
  (
    {
      children,
      onClick,
      label,
      isSearchable,
      searchPlaceholder,
      searchValue,
      withCustomInputClick,
      onSearchChange,
      isMenuOpen,
      placeholder,
      value,
      name,
      meta,
      withoutToggleChevron,
      handleClear,
      className,
      loading,
      ...props
    },
    ref
  ) => {
    const handleClick: MouseEventHandler = useCallback(
      (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (isSearchable && isMenuOpen) {
          return;
        }

        onClick(event);
      },
      [isMenuOpen, isSearchable, onClick]
    );

    const IconRight = useCallback(
      ({ isMenuOpen }: { isMenuOpen: boolean }) => {
        if (loading) {
          return <Spinner size={"sm"} />;
        }
        if (value && isMenuOpen) {
          return <CloseIcon width={24} height={24} />;
        }
      },
      [loading, value]
    );

    return (
      <TextField
        autoComplete="none"
        type="text"
        label={label}
        ref={ref as any}
        value={value || (isSearchable && isMenuOpen ? value : placeholder)}
        onClick={withCustomInputClick ? onClick : handleClick}
        meta={meta}
        onChange={(isSearchable && onSearchChange) || undefined}
        iconRight={<IconRight isMenuOpen={isMenuOpen} />}
        iconRightOnClick={value ? handleClear : undefined}
        iconLeft={
          isSearchable && isMenuOpen && <SearchIcon width={24} height={24} color={scssVariables.foregroundLow} />
        }
        data-testid={`dropdown-toggle-${name || label}-test-id`}
        placeholder={isSearchable ? searchPlaceholder : undefined}
        name={name}
        className={cn(className, {
          [classes.notSelected]: !value,
        })}
        {...props}
      />
    );
  }
);

ShareholderToggle.displayName = "ShareholderToggle";

export default ShareholderToggle;
