import { Action, action, createContextStore, persist } from "easy-peasy";

interface LocalCompanyStoreModel {
  // state
  companyType: "few-owners" | "many-owners" | null;
  // actions
  setCompanyType: Action<this, this["companyType"]>;
  // thunks
}

const LocalCompanyStore = createContextStore<LocalCompanyStoreModel>(
  persist(
    {
      // state
      companyType: null,
      // actions
      setCompanyType: action((state, payload) => {
        state.companyType = payload;
      }),
      // thunks
    },
    {
      storage: "localStorage",
      allow: ["companyType"],
    }
  ),
  { name: "Onboard store" }
);

export default LocalCompanyStore;
