import React, { FC, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { isEmpty, isNil, pluck } from "ramda";

import { Dropdown } from "common/components/atoms";
import { useStoreActions, useStoreState } from "store/store";

type SigningManagerFieldProps = {
  label?: string;
  error?: string;
  isTouched?: boolean;
  selectedId?: null | number;
  onChange: (value: string | number) => void;
};

const SigningManagerField: FC<SigningManagerFieldProps> = ({
  selectedId,
  label = "Signing manager",
  error,
  isTouched,
  onChange,
}) => {
  const { companyId } = useParams<{ companyId: string }>();

  const companySigningManagers = useStoreState((state) => state.company.companySigningManagers);
  const user = useStoreState((state) => state.account.user);
  const getCompanySigningManagersThunk = useStoreActions((actions) => actions.company.getCompanySigningManagersThunk);

  const selectedOption = useMemo(
    () => companySigningManagers?.find((el) => el.userId === selectedId),
    [companySigningManagers, selectedId]
  );

  useEffect(() => {
    getCompanySigningManagersThunk(Number(companyId));
  }, [companyId, getCompanySigningManagersThunk]);

  useEffect(() => {
    const foundedManager = companySigningManagers?.find((el) => el.userId === selectedId);

    if (!isNil(selectedId) && isNil(foundedManager) && !isNil(companySigningManagers)) {
      const initialSelectedManager = companySigningManagers?.find((el) => el.userId === user?.id);

      if (initialSelectedManager) {
        onChange(initialSelectedManager?.userId);
      } else {
        onChange(companySigningManagers[0].userId);
      }
    }

    if (isNil(selectedId) && !isNil(companySigningManagers) && !isEmpty(companySigningManagers)) {
      const initialSelectedManager = companySigningManagers.find((el) => el.userId === user?.id);

      if (initialSelectedManager) {
        onChange(initialSelectedManager?.userId);
      } else {
        onChange(companySigningManagers[0].userId);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId, companySigningManagers]);

  return (
    <Dropdown
      label={label}
      error={error}
      isTouched={isTouched}
      selectedValue={selectedOption?.name}
      options={pluck("name", companySigningManagers || [])}
      onChange={(val) => {
        const selectedOption = companySigningManagers?.find((el) => el.name === val);

        if (selectedOption) {
          onChange(selectedOption.userId);
        }
      }}
    />
  );
};

export default SigningManagerField;
