import { FC } from "react";
import { useFormikContext } from "formik";

import TextField from "common/components/atoms/TextField/TextField";
import ShareholderSearch from "common/components/molecules/ShareholderSearch/ShareholderSearch";
import { createTranslation, TranslationNS } from "translation";

import { fields as f } from "./fields";
import { ShareDetailsFormValues } from "./ReceiverDetails.types";

const t = createTranslation(TranslationNS.common, "molecules.sharedDetails.business");

const SearchableCompanyNameField: FC<{ isSearchable?: boolean; isOptional?: boolean }> = ({
  isOptional,
  isSearchable,
}) => {
  const { values, errors, touched, handleChange, handleBlur } = useFormikContext<ShareDetailsFormValues>();

  if (!isSearchable) {
    return (
      <TextField
        isOptional={isOptional}
        label={t("organizationName")}
        value={values[f.companyName]}
        error={errors[f.companyName]}
        isTouched={touched[f.companyName]}
        name={f.companyName}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    );
  }

  return (
    <ShareholderSearch
      noMeta
      focusOnMount
      placeholder=""
      updateSearchField
      showJustCompanies
      notShowEmptyResults
      name={f.companyName}
      label={t("organizationName")}
      onBlur={handleBlur}
      error={errors[f.companyName]}
      isTouched={touched[f.companyName]}
      previousValue={values[f.companyName]}
    />
  );
};

export default SearchableCompanyNameField;
