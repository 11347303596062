import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import cn from "classnames";
import { add, format } from "date-fns";
import { useFormikContext } from "formik";
import * as R from "ramda";
import { isNil } from "ramda";

import { DatePicker, Helper, PeriodPickerFormik, TextField, Ui } from "common/components/atoms";
import SigningManagerField from "common/components/molecules/signing-manager/SigningManagerField";
import { InstrumentTypesIdsEnum, PeriodTimeEnum } from "common/enums/enum";
import useCurrency from "common/hooks/useCurrency";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { newPlanFieldsNames as f } from "common/plan/planFormFields";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { PlanForm } from "../../../../create-one-off-plans/types";
import { periodVariants } from "../../form-fields";
import classes from "./stock-options.module.scss";

type PropsType = {
  shareClass?: string;
  planType?: number;
  isExistingPlan?: boolean;
};

const t = createTranslation(TranslationNS.pages, "createPlan.basics.planSidebar");

const StockOptionsDetails: FC<PropsType> = ({ planType }) => {
  const { currencySymbol } = useCurrency();
  const fNumber = useFormatNumbers();
  const { companySigningManagers } = useStoreState((state) => state.company);
  const program = useStoreState((state) => state.programModel.program);
  const [currentSharePrice, setCurrentSharePrice] = useState<number>(0);

  const { values, handleChange, handleBlur, touched, errors, setFieldValue } = useFormikContext<PlanForm>();

  const signingManager = useMemo(
    () => companySigningManagers?.find((el) => el.userId === values.managerId),
    [companySigningManagers, values.managerId]
  );

  const expirationDate = useMemo(() => {
    let timeFrame = "days";
    if (values.optionsExpirationTimeUnit === PeriodTimeEnum.YEAR) {
      timeFrame = "years";
    } else if (values.optionsExpirationTimeUnit === PeriodTimeEnum.MONTH) {
      timeFrame = "months";
    }

    return add(values.vestingStartsAt ? new Date(values.vestingStartsAt) : new Date(), {
      [timeFrame]: values.optionsExpiration || 0,
    });
  }, [values.optionsExpiration, values.optionsExpirationTimeUnit, values.vestingStartsAt]);

  const planTypeValue = useMemo(() => {
    if (planType === InstrumentTypesIdsEnum.RSA) {
      return "Restricted Stock Awards";
    }
    if (planType === InstrumentTypesIdsEnum.RSU) {
      return "Restricted Stock Units";
    }

    if (planType === InstrumentTypesIdsEnum.OPTION) {
      return "Stock options";
    }
  }, [planType]);

  const handleDateChange = useCallback(
    (date: Date | string, name?: string) => {
      if (name) {
        setFieldValue(name, date);
      }
    },
    [setFieldValue]
  );

  useEffect(() => {
    if (program?.companyId) {
      axios
        .get(`/api/company/share-price/${program?.companyId}`)
        .then((res) => {
          setCurrentSharePrice(res.data.sharePrice);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [program?.companyId]);

  useEffect(() => {
    if (!isNil(signingManager)) {
      setFieldValue("signingManagerEmail", signingManager?.email);
    }
  }, [setFieldValue, signingManager, signingManager?.email, values.managerId]);

  return (
    <div>
      <Row className="mb-4">
        <Col>
          <Helper.Question questionId={f.numberOfShares} type="input">
            <TextField
              type="number"
              label={t("optionsAmount")}
              value={values.numberOfShares}
              isTouched={touched.numberOfShares}
              error={errors.numberOfShares}
              name={f.numberOfShares}
              onBlur={handleBlur}
              onChange={handleChange}
              info={
                values.numberOfShares
                  ? t("pricePerShare", {
                      price: fNumber(values.numberOfShares * R.defaultTo(0, values.exercisePrice), "value"),
                    })
                  : undefined
              }
            />
          </Helper.Question>
        </Col>
        <Col>
          <Helper.Question questionId={f.purchasePrice} type="input">
            <TextField
              type="number"
              label={t("optionsPrice")}
              value={String(values.purchasePrice)}
              isTouched={touched.purchasePrice}
              error={errors.purchasePrice}
              name={f.purchasePrice}
              onBlur={handleBlur}
              onChange={handleChange}
              iconRight={currencySymbol}
            />
          </Helper.Question>
        </Col>
      </Row>
      <Row>
        <Col>
          <Helper.Answer
            className="mb-3"
            answerId={f.numberOfShares}
            text={t("optionsAmountAnswer")}
            linkText={t("stockOptionsReadMore")}
            link="/"
          />
          <Helper.Answer
            className="mb-3"
            answerId={f.purchasePrice}
            text={t("optionsPriceAnswer")}
            linkText={t("stockOptionsReadMore")}
            link="/"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Helper.Question questionId={f.reason} type="input">
            <TextField
              isOptional
              type="string"
              label={t("grantReason")}
              value={values.reason}
              onChange={handleChange}
              name={f.reason}
            />
          </Helper.Question>
        </Col>
      </Row>
      <Row>
        <Col>
          <Helper.Answer className="mt-3" answerId={f.reason} text={t("grantReasonAnswer")} />
        </Col>
      </Row>

      <Row className="my-4">
        <Col>
          <Helper.Question questionId={f.exercisePrice} type="input">
            <TextField
              type="number"
              label={t("exercisePrice")}
              value={String(values.exercisePrice)}
              isTouched={touched.exercisePrice}
              error={errors.exercisePrice}
              name={f.exercisePrice}
              onBlur={handleBlur}
              onChange={handleChange}
              iconRight={currencySymbol}
            />
          </Helper.Question>
        </Col>
        <Col>
          <div className="mt-5">{t("currentSharePrice", { price: fNumber(currentSharePrice, "sharePrice") })}</div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Helper.Answer
            className="mb-3"
            answerId={f.exercisePrice}
            text={t("exercisePriceAnswer")}
            linkText={t("stockOptionsReadMore")}
            link="/"
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <DatePicker
            className={classes["date-picker"]}
            label={t("boardApprovalDate")}
            isClearable
            date={values.approvedAt}
            isDateOnlyString
            name={f.approvedAt}
            onChange={handleDateChange}
            onBlur={handleBlur}
            isOptional
          />
        </Col>
        <Col>
          <DatePicker
            className={classes["date-picker"]}
            label={t("grantDate")}
            date={values.grantedAt}
            isDateOnlyString
            name={f.grantedAt}
            onBlur={handleBlur}
            onChange={handleDateChange}
          />
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <Helper.Question questionId={f.optionsExpiration} type="input">
            <PeriodPickerFormik
              label={t("expirationDate")}
              periodVariants={periodVariants}
              nameAmount={f.optionsExpiration}
              namePeriod={f.optionsExpirationTimeUnit}
            />
          </Helper.Question>
        </Col>
        <Col>
          <SigningManagerField
            error={errors.managerId}
            isTouched={touched.managerId}
            selectedId={values.managerId}
            onChange={(id) => {
              setFieldValue("managerId", id);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Helper.Answer
            className="mt-3"
            answerId={f.optionsExpiration}
            text={t("expirationDateAnswer")}
            linkText={t("stockOptionsReadMore")}
            link="/"
          />
        </Col>
      </Row>

      <div className={cn(classes["neutral-plate"], "mt-3")}>
        <span className={classes["share-details-title"]}>{t("shareDetailsDescriptionTitle")}</span>
        <div className="d-flex justify-content-between pt-2">
          <div>
            <Ui.m>
              {t("shareClass")}: <strong>{program?.shareClassName}</strong>
            </Ui.m>
            <Ui.m className="my-2">
              {t("type")} <strong>{planTypeValue}</strong>
            </Ui.m>
            <Ui.m>
              {t("expirationDate")}: <strong>{format(expirationDate, "dd.MM.yyy")}</strong>
            </Ui.m>
            {values?.managerId ? (
              <Ui.m className="mt-2">
                {t("signingManager")}:{" "}
                <strong>
                  {signingManager?.name} ({signingManager?.email})
                </strong>
              </Ui.m>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockOptionsDetails;
