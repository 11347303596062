import { FC } from "react";

import { Tag, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { ArrowRightIcon, CalendarIcon, CommonFileIcon, DollarCircleIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import { Transaction, TransactionCategory, TransactionSharesType, TransactionStatus } from "../../../../types";
import formatTransactionDate from "../../../format-transaction-date";

type PropsTypes = {
  transaction: Transaction;
  isMini?: boolean;
  isPortfolio?: boolean;
  className?: string;
};

const tTransactions = createTranslation(TranslationNS.pages, "company.transactions");
const t = createTranslation(TranslationNS.pages, "company.transactions.transactionItem");

const IssueSharesAndBuySellHeader: FC<PropsTypes> = ({ transaction, isMini, isPortfolio, className }) => {
  const isPending = transaction.statusId === TransactionStatus.Pending;
  const fNumber = useFormatNumbers(transaction.currencySymbol);

  const additionalName =
    transaction.categoryId === TransactionCategory.Issue
      ? `${transaction.toName || "..."} • `
      : `${transaction.fromName || "..."}  ->  ${transaction.toName || "..."} • `;

  const tagName = transaction.typeName === "Shares issue" ? "" : `(${transaction.typeName})`;

  return (
    <div className={className}>
      {isPortfolio && transaction.editedAfterConfimation ? (
        <div className="d-flex mb-1">
          <Ui.m bold className="d-flex me-2">{`${additionalName} ${fNumber(transaction.numberOfShares, "amount")} ${t(
            TransactionSharesType[
              transaction.categoryId === TransactionCategory.Issue ? TransactionCategory.Sell : TransactionCategory.Sell
            ]
          )} ${tagName}`}</Ui.m>
          <Tag variant="closed" size="s">
            <Ui.xs>{t("editedTag")}</Ui.xs>
          </Tag>
        </div>
      ) : (
        <Ui.m bold className="mb-1 d-flex">{`${additionalName} ${fNumber(transaction.numberOfShares, "amount")} ${t(
          TransactionSharesType[
            transaction.categoryId === TransactionCategory.Issue ? TransactionCategory.Sell : TransactionCategory.Sell
          ]
        )} ${tagName}`}</Ui.m>
      )}

      <div className="d-flex" style={{ color: scssVariables.foregroundLow }}>
        <Tag className="me-2" variant={isPending ? "closed" : "complete"}>
          <Ui.xs>{transaction.categoryName}</Ui.xs>
        </Tag>

        {isPending ? (
          <Tag variant="information" className="d-flex align-items-center me-2">
            <CalendarIcon height={16} width={16} color={scssVariables.foregroundLow} className="me-half" />
            <Ui.xs>{formatTransactionDate(transaction.transactedAt) + ` • ${tTransactions("pending")}`}</Ui.xs>
          </Tag>
        ) : (
          <div className="d-flex align-items-center me-2">
            <CalendarIcon height={16} width={16} color={scssVariables.foregroundLow} className="me-half" />
            <Ui.xs>{formatTransactionDate(transaction.transactedAt)}</Ui.xs>
          </div>
        )}

        <div className="d-flex align-items-center me-2">
          <Ui.xs className="me-half">{transaction.fromName || "..."}</Ui.xs>
          <ArrowRightIcon height={16} width={16} color={scssVariables.foregroundLow} />
          <Ui.xs className="ms-half">{transaction.toName || "..."}</Ui.xs>
        </div>

        {!isMini && (
          <div className="d-flex align-items-center me-2">
            <DollarCircleIcon />
            <Ui.xs className="ms-half">
              {`${fNumber(transaction.transactionTotal, "value")} • ${fNumber(
                transaction.sharePrice,
                "sharePrice"
              )}/${t("share")}`}
            </Ui.xs>
          </div>
        )}

        {transaction.description && (
          <div className="d-flex align-items-center me-2">
            <CommonFileIcon height={16} width={16} color={scssVariables.foregroundLow} />
          </div>
        )}
      </div>
    </div>
  );
};

export default IssueSharesAndBuySellHeader;
