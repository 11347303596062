import { useContext, useMemo } from "react";
import { useFormikContext } from "formik";

import useCompany from "../Company/useCompany";
import RenderStakeholderFields from "../fields/RenderStakeholderFields/RenderStakeholderFields";
import usePerson from "../Personal/usePerson";
import { StakeholderField, StakeholderFormValues } from "../Stakeholder.types";
import { StakeholderSectionContext } from "../StakeholderSection";

const otherFields: StakeholderField[] = [];
const ShowRequiredFields = () => {
  const updatedFields = useContext(StakeholderSectionContext).updatedFields;
  const { values } = useFormikContext<StakeholderFormValues>();

  const { topFields: companyFields } = useCompany(updatedFields);
  const { topFields: personFields } = usePerson(updatedFields);

  const topFields = useMemo(() => {
    const showFields = Object.keys(updatedFields || {});
    const fields = values.isCompanyOwned ? companyFields : personFields;

    return fields.filter((field) => showFields.includes(field.key));
  }, [companyFields, personFields, updatedFields, values.isCompanyOwned]);

  return (
    <div className="mt-4">
      <RenderStakeholderFields topFields={topFields} otherFields={otherFields} />
    </div>
  );
};
export default ShowRequiredFields;
