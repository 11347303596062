import { ChangeEvent, FC, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import BDropdown from "react-bootstrap/Dropdown";
import axios from "axios";
import cn from "classnames";
import { useFormikContext } from "formik";
import { clone, defaultTo } from "ramda";

import DropdownClasses from "common/components/atoms/Dropdown/Dropdown.module.scss";
import { ShareDetailsFormValues } from "common/components/molecules/ReceiverDetails/ReceiverDetails.types";
import useDebounce from "common/hooks/useDebounce";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import ShareholderSearchDropdownMenu from "./ShareholderSearch.menu";
import ShareholderSearchModal from "./ShareholderSearch.modal";
import classes from "./ShareholderSearch.module.scss";
import ShareholderToggle from "./ShareholderToggle";

const t = createTranslation(TranslationNS.common, "molecules.shareholderSearch");

type ShareholderSearchProps = {
  label?: string;
  noMeta?: boolean;
  placeholder?: string;
  focusOnMount?: boolean;
  showJustUsers?: boolean;
  showJustCompanies?: boolean;
  updateSearchField?: boolean;
  onBlur?: (e?: any) => void;
  previousValue?: string;
  name?: string;
  error?: string;
  isTouched?: boolean;
  className?: string;
  notShowEmptyResults?: boolean;
};

const ShareholderSearch: FC<ShareholderSearchProps> = ({
  showJustUsers,
  showJustCompanies,
  updateSearchField,
  label = t("label"),
  placeholder = t("placeholder"),
  onBlur,
  previousValue,
  name,
  error,
  isTouched,
  className,
  notShowEmptyResults,
}) => {
  const { companyId } = useStoreState((state) => state.activeCompanyModel);
  const toggleRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState<string>();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [matchShareholderModalOpen, setMatchShareholderModalOpen] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<ShareDetailsFormValues[]>([]);
  const [canShowResults, setCanShowResults] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [safariInteraction, setSafariInteraction] = useState<boolean>(false);

  const { values, setFieldValue, setValues } = useFormikContext<ShareDetailsFormValues>();

  const debouncedSearchTerm = useDebounce(value, 1000);

  const filteredOptions = useMemo(() => {
    let copiedResults = clone(searchResults);

    if (showJustCompanies) {
      copiedResults = copiedResults.filter((el) => el.isCompanyOwned);
    }

    if (showJustUsers) {
      copiedResults = copiedResults.filter((el) => !el.isCompanyOwned);
    }

    copiedResults = copiedResults.sort((a, b) => {
      if (values.isCompanyOwned) {
        return Number(b.isCompanyOwned) - Number(a.isCompanyOwned);
      } else {
        return Number(a.isCompanyOwned) - Number(b.isCompanyOwned);
      }
    });

    if (debouncedSearchTerm) {
      return copiedResults.filter(
        (opt: ShareDetailsFormValues) =>
          `${opt.firstName?.toLowerCase()} ${opt.lastName?.toLowerCase()}`.includes(
            debouncedSearchTerm?.toLowerCase()
          ) ||
          `${opt.companyName?.toLowerCase()} (${opt.firstName?.toLowerCase()} ${opt.lastName?.toLowerCase()})`.includes(
            debouncedSearchTerm?.toLowerCase()
          ) ||
          `${opt.companyName?.toLowerCase()} ${opt.firstName?.toLowerCase()} ${opt.lastName?.toLowerCase()}`.includes(
            debouncedSearchTerm?.toLowerCase()
          ) ||
          `${opt.organizationNumber?.toLowerCase()} (${opt.companyName?.toLowerCase()})`.includes(
            debouncedSearchTerm?.toLowerCase()
          ) ||
          opt.firstName?.toLowerCase().includes(debouncedSearchTerm?.toLowerCase()) ||
          opt.lastName?.toLowerCase().includes(debouncedSearchTerm?.toLowerCase()) ||
          opt.companyName?.toLowerCase()?.includes(debouncedSearchTerm?.toLowerCase()) ||
          opt.organizationNumber?.toLowerCase()?.includes(debouncedSearchTerm?.toLowerCase())
      );
    }

    return copiedResults;
  }, [searchResults, showJustCompanies, showJustUsers, debouncedSearchTerm, values.isCompanyOwned]);

  const clearValueData = useCallback(() => {
    toggleRef?.current?.focus();

    setValue("");
    if (name) {
      setFieldValue(name, "");
    }
  }, [name, setFieldValue]);

  const handleSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
      if (updateSearchField) {
        setFieldValue(values.isCompanyOwned ? "companyName" : "firstName", e.target.value);
      }
    },
    [updateSearchField, setFieldValue, values.isCompanyOwned]
  );

  const handleSelect = useCallback(
    (e: ShareDetailsFormValues) => {
      toggleRef?.current?.blur();
      const valuesToUpdate: ShareDetailsFormValues = {
        address: defaultTo("", e.address),
        businessEmail: defaultTo("", e.businessEmail),
        businessPostAddress: defaultTo("", e.businessPostAddress),
        companyName: defaultTo("", e.companyName),
        dateOfBirth: defaultTo(null, e.dateOfBirth),
        email: defaultTo("", e.email),
        firstName: e.firstName,
        isCompanyOwned: e.isCompanyOwned,
        lastName: e.lastName,
        organizationNumber: defaultTo("", e.organizationNumber),
        relationshipTypeId: e.relationshipTypeId,
        countryId: defaultTo(undefined, e.countryId),
        phoneNumber: defaultTo(undefined, e.phoneNumber),
        documentStatusId: defaultTo(0, e.documentStatusId),
      };

      if (e.stakeholderId) {
        valuesToUpdate.stakeholderId = e.stakeholderId;
      }

      setValues({
        ...values,
        ...valuesToUpdate,
      });

      setIsFocused(false);
      setIsMenuOpen(false);
    },
    [setValues, values]
  );

  const blurOnDocumentClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();

      const target = e?.target as HTMLElement;

      if (
        target.getAttribute("data-testid")?.includes("shareholder-item-test-id") ||
        target.getAttribute("data-testid")?.includes("shareholder-test-id") ||
        target.getAttribute("data-testid")?.includes("close-search-button") ||
        // the reason because we're using svg icons, and svg className is function
        (typeof target?.className === "string" && target?.className?.includes("shareholder-item-test-id")) ||
        // mark tag is using because highlighted element in search is <mark>element</mark>
        target?.tagName === "MARK"
      ) {
        return;
      }

      if (!isMenuOpen) {
        return;
      }

      clearValueData();
    },
    [isMenuOpen, clearValueData]
  );

  const searchShareholderRequest = useCallback(async () => {
    setCanShowResults(false);

    if (!debouncedSearchTerm?.trim()) {
      setCanShowResults(false);
      setSearchResults([]);
      return;
    }

    try {
      setLoading(true);
      const request = await axios.post<{ results: ShareDetailsFormValues[] }>(
        "/api/equity-management/shareholder/search",
        {
          companyId,
          searchString: debouncedSearchTerm,
        }
      );

      if (request.status === 200 && request.data.results.length) {
        setSearchResults(request.data.results);
        setCanShowResults(true);
      }

      if (request.status === 200 && !request.data.results.length) {
        setCanShowResults(true);
      }
    } catch (e) {
      console.warn({ e });
    } finally {
      setLoading(false);
    }
  }, [companyId, debouncedSearchTerm]);

  useEffect(() => {
    searchShareholderRequest();
  }, [searchShareholderRequest]);

  useEffect(() => {
    if (previousValue) {
      setValue(previousValue);
    }
  }, [matchShareholderModalOpen, previousValue]);

  const handleBlur = useCallback(
    (e: SyntheticEvent) => {
      setIsFocused(false);
      onBlur?.(e);
    },
    [onBlur]
  );

  const handleToggle = useCallback(
    (nextShow: boolean) => {
      if (isFocused && !nextShow) {
        setIsMenuOpen(true);
      } else {
        setIsMenuOpen(nextShow);
      }
    },
    [isFocused]
  );

  const handleFocus = useCallback(() => {
    setIsFocused(true);
    if (!safariInteraction) {
      setIsMenuOpen(true);
      setSafariInteraction(true);
    }
  }, [safariInteraction]);

  return (
    <>
      <BDropdown
        show={isMenuOpen}
        className={cn(DropdownClasses["dropdown"], className, {
          [DropdownClasses["isSearchable"]]: isMenuOpen,
        })}
        onToggle={handleToggle}
      >
        <div className="position-relative">
          <BDropdown.Toggle
            isSearchable
            label={label}
            ref={toggleRef as any}
            data-testid="shareholder-item-test-id"
            value={value}
            name={name}
            searchValue={value}
            as={ShareholderToggle}
            withoutToggleChevron
            withCustomInputClick
            isMenuOpen={isMenuOpen}
            searchPlaceholder={placeholder}
            handleClear={clearValueData}
            onSearchChange={handleSearch}
            className={classes["toggle"]}
            onBlur={handleBlur}
            onFocus={handleFocus}
            loading={loading}
            error={error}
            isTouched={isTouched}
          />
        </div>

        <ShareholderSearchDropdownMenu
          options={filteredOptions}
          isSmallFont={showJustCompanies || showJustUsers}
          searchValue={canShowResults ? debouncedSearchTerm : undefined}
          handleSelect={handleSelect}
          notShowEmptyResults={notShowEmptyResults}
        />
      </BDropdown>

      <ShareholderSearchModal
        show={matchShareholderModalOpen}
        handleClose={() => {
          setMatchShareholderModalOpen(false);
        }}
      />
    </>
  );
};

export default ShareholderSearch;
