import { FC, useCallback, useEffect, useState } from "react";
import { useFormikContext } from "formik";

import TextField from "common/components/atoms/TextField/TextField";
import { createTranslation, TranslationNS } from "translation";

import ShareholderSearchV2 from "../ShareholderSearchV2/ShareholderSearchV2";
import { SearchStakeholderResult, SearchUser } from "../ShareholderSearchV2/types";
import { fields as f } from "./fields";
import { ShareDetailsFormValues } from "./ReceiverDetails.types";

const t = createTranslation(TranslationNS.common, "molecules.sharedDetails.personal");

type PropsTypes = {
  selectedSearchUser: SearchStakeholderResult | null;
  onChangeUser: (user: SearchStakeholderResult | null) => void;
  isSearchable?: boolean;
  isOptional?: boolean;
  isDisabled?: boolean;
};

const SearchableFirstNameField: FC<PropsTypes> = ({
  isSearchable,
  isOptional,
  isDisabled,
  selectedSearchUser,
  onChangeUser,
}) => {
  const { values, errors, touched, handleChange, handleBlur } = useFormikContext<ShareDetailsFormValues>();

  if (!isSearchable) {
    return (
      <TextField
        isOptional={Boolean(isOptional)}
        label={t("firstName")}
        value={values[f.firstName]}
        error={errors[f.firstName]}
        isTouched={touched[f.firstName]}
        name={f.firstName}
        onChange={handleChange}
        onBlur={handleBlur}
        isDisabled={isDisabled}
      />
    );
  }

  return (
    <ShareholderSearchV2
      label={t("firstName")}
      placeholder=""
      selectedUser={selectedSearchUser}
      onSelect={onChangeUser}
      filterBy="users"
      onBlur={handleBlur}
      name={f.firstName}
      error={errors[f.firstName]}
      isTouched={touched[f.firstName]}
      hideEmptyResultsMenu
      isDisabled={isDisabled}
    />
  );
};

export default SearchableFirstNameField;
