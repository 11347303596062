import { FC } from "react";

import { NewAvatar, Ui } from "common/components/atoms";
import { SearchUser } from "common/components/molecules/ShareholderSearchV2/types";
import { CloseIcon } from "common/icons/svg";

type PropsTypes = {
  seller?: SearchUser;
  onRemoveSeller?: () => void;
};

const createInitials = (user: SearchUser) => {
  if (user.isCompanyOwned) {
    if (user.companyName) {
      return user.companyName[0].toUpperCase();
    }
  }

  if (user.firstName && user.lastName) {
    return `${user.firstName[0]}${user.lastName[0]}`.toUpperCase();
  }
};

const SellerCard: FC<PropsTypes> = ({ seller, onRemoveSeller }) => {
  if (!seller) return null;

  return (
    <div className="d-flex align-items-center justify-content-between pb-3 rounded-1">
      <div className="d-flex align-items-center">
        <NewAvatar className="me-2" initials={createInitials(seller)} imageUrl={seller?.avatarFilePath || undefined} />
        {seller?.isCompanyOwned ? (
          <div>
            <Ui.xl bold>{seller.companyName}</Ui.xl>
            <Ui.s className="text-low">{seller?.firstName + " " + seller?.lastName}</Ui.s>
          </div>
        ) : (
          <Ui.xl bold>{seller?.firstName + " " + seller?.lastName}</Ui.xl>
        )}
      </div>
      <div style={{ cursor: "pointer" }} onClick={onRemoveSeller}>
        <CloseIcon />
      </div>
    </div>
  );
};

export default SellerCard;
