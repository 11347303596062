import { InstrumentTypesIdsEnum, InstrumentTypesNamesEnum } from "common/enums/enum";

import { SingleCommonDocumentType } from "../documents/DocumentsContext";
import { BuySellFields } from "./components/forms/buy-sell/form-fields";
import { CapitalIncreaseGeneralFields } from "./components/forms/capital-increase/steps/capital-increase-general/form-fields";
import { CapitalIncreaseTransactionsFields } from "./components/forms/capital-increase/steps/capital-increase-transactions-table/form-fields";
import { ImportTransactionFormValues } from "./components/forms/capital-increase/steps/capital-increase-transactions-table/use-import-transaction-form";
import { ChangeNominalValueFields } from "./components/forms/change-nominal-value/form-fields";
import { DocumentationDetailsFieldsType } from "./components/forms/form-sections/documentation-details/types";
import { ReceiveDetailsFieldsType } from "./components/forms/form-sections/receiver-details/types";
import { SellerDetailsFieldsType } from "./components/forms/form-sections/seller-details/types";
import { ShareDetailsFieldsType } from "./components/forms/form-sections/shares-details/types";
import { SplitFieldsTypes } from "./components/forms/form-sections/split-details/types";
import { TransactionDetailsFieldsType } from "./components/forms/form-sections/transaction-details/types";
import { IssueSharesFields } from "./components/forms/issue-shares/form-fields";

export const TransactionCategory = {
  Issue: 1,
  Sell: 2,
  Split: 3,
  ChangeNominalValue: 4,
  Bundle: 5,
  Deletion: 6, // Used only for 1 company as exception there is no way for creating it with UI
} as const;

export const TransactionStatus = {
  Pending: 1,
  Confirmed: 2,
} as const;

export const TransactionTypes = {
  RsaSharesIssued: 1,
  InvestorSharesIssued: 2,
  OptionsExercised: 3,
  NoteConversion: 4,
  LoanConversion: 5,
  WarrantSettlement: 6,
  PlanTermination: 7,
  CaptableSetup: 8,
  BuySell: 9,
  Foundation: 12,
  Split: 13,
  StandAloneRsa: 14,
  NominalValue: 15,
  CapitalIncreaseBundle: 16,
  CashDeposit: 17,
  ReceivableConversion: 18,
  Other: 19,
  AllCompanySharesDeleted: 20,
  StakeholderSharesDeleted: 21,
} as const;

export const TransactionSharesType: Record<TransactionCategoryIds, string> = {
  [TransactionCategory.Issue]: "newShares",
  [TransactionCategory.Sell]: "existingShares",
  [TransactionCategory.Split]: "splitShares",
  [TransactionCategory.ChangeNominalValue]: "changeNominalValue",
  [TransactionCategory.Bundle]: "bundle",
  [TransactionCategory.Deletion]: "deletion",
} as const;

export type TransactionCategoryIds = (typeof TransactionCategory)[keyof typeof TransactionCategory];
export type TransactionStatusIds = (typeof TransactionStatus)[keyof typeof TransactionStatus];
export type TransactionTypesIds = (typeof TransactionTypes)[keyof typeof TransactionTypes];

export type Transaction = {
  transactionId: number;
  transactedAt: string;
  typeName?: string;
  categoryName?: string;
  transactionTypeId: TransactionTypesIds;
  categoryId: TransactionCategoryIds;
  statusId: TransactionStatusIds;
  numberOfShares: number;
  fromName?: string;
  fromAvatar?: string;
  fromInitials?: string;
  fromRepresentativeName?: string;
  fromIsCompanyOwned: boolean;
  toName?: string;
  toInitials?: string;
  toAvatar?: string;
  toIsCompanyOwned: boolean;
  toRepresentativeName?: string;
  currencySymbol?: string;
  sharePrice: number;
  shareClassName?: string;
  description?: string;
  shareSeries: string[]; // "1-500"
  transactionTotal: number;
  editedAfterConfimation: boolean; // typo in API

  multiplier?: number; // only for split transactions
  sharePriceBefore?: number; // only for split transactions
  numberOfSharesBefore?: number; // only for split transactions

  numberOfBundledTransactions?: number; // only for bundle
  transactionBundleId?: number; // only for bundle
  eventName?: string; // only for bundle

  canEditConfirmed: boolean;
};

export type GetTransactionsDTO = {
  transactions: Transaction[];
  companyHasShares: boolean;
  transactedAtMax: string;
  nominalValue: number;
};

// issue shares
export type IssueSharesGetResponseDTO = TransactionDetailsFieldsType &
  ShareDetailsFieldsType &
  ReceiveDetailsFieldsType &
  DocumentationDetailsFieldsType & {
    transactionId: number;
    transactionBundleId?: number;
    statusId: TransactionStatusIds;
    documentFiles: Pick<SingleCommonDocumentType, "fileName" | "downloadId" | "fileId">[];
  };

export type IssueSharesPostDTO = {
  [IssueSharesFields.companyId]: string;
} & TransactionDetailsFieldsType &
  ShareDetailsFieldsType &
  ReceiveDetailsFieldsType &
  DocumentationDetailsFieldsType;

export type IssueSharesPostResponseDTO = {
  transactionId: number;
};

export type IssueSharesEditDTO = TransactionDetailsFieldsType &
  ReceiveDetailsFieldsType &
  ShareDetailsFieldsType &
  DocumentationDetailsFieldsType & {
    transactionId: number;
    [IssueSharesFields.companyId]: number;
  };

export type IssueSharesCheckDTO = TransactionDetailsFieldsType &
  ReceiveDetailsFieldsType &
  ShareDetailsFieldsType & {
    transactionId: number;
    [IssueSharesFields.companyId]: number;
  };

// buy sell

// used for initial seller in form
export type SellerDetails = {
  stakeholderId: number;
  firstName: string;
  lastName: string;
  isCompanyOwned: boolean;
  companyName: string;
  avatarFilePath: string;
};

export type BuySellGetResponseDTO = TransactionDetailsFieldsType &
  ReceiveDetailsFieldsType &
  ShareDetailsFieldsType &
  DocumentationDetailsFieldsType & {
    transactionId: number;
    transactionTypeId?: number;
    transactionBundleId?: number;
    sellerDetails: SellerDetails;
    documentFiles: Pick<SingleCommonDocumentType, "fileName" | "downloadId" | "fileId">[];
    canEditConfirmed: boolean;
  };

export type BuySellPostResponseDTO = { transactionId: number };

export type BuySellPostDTO = Omit<TransactionDetailsFieldsType, "transactionTypeId"> &
  ReceiveDetailsFieldsType &
  SellerDetailsFieldsType &
  ShareDetailsFieldsType &
  DocumentationDetailsFieldsType & {
    [BuySellFields.companyId]: number;
    [BuySellFields.transactionTypeId]?: number;
  };

export type BuySellEditDTO = Omit<TransactionDetailsFieldsType, "transactionTypeId"> &
  ReceiveDetailsFieldsType &
  SellerDetailsFieldsType &
  ShareDetailsFieldsType &
  DocumentationDetailsFieldsType & {
    [BuySellFields.companyId]: number;
    transactionId: number;
    [BuySellFields.transactionTypeId]?: number;
  };

export type BuySellCheckDTO = Omit<TransactionDetailsFieldsType, "transactionTypeId"> &
  ReceiveDetailsFieldsType &
  SellerDetailsFieldsType &
  ShareDetailsFieldsType & {
    [BuySellFields.companyId]: number;
    transactionId: number;
  };

export type CheckResponseDTO = {
  failedTransactions: Transaction[];
  hasErrorsAfterEdit: boolean;
};

export type ConfirmIssueSharesTransactionPostDTO = {
  transactionId: number;
  transactionBundleId?: number;
  transactedAt?: string;
} & Omit<TransactionDetailsFieldsType, "transactionTypeId">;

export type AvailableShareClassesPostDTO = {
  companyId: number;
  stakeholderId: number;
  transactionDate: string;
  transactionId?: number; // needed if API used for editing case. It will include shares from transaction as available in shareclass
};

export type AvailableShareClass = {
  shareClassId: number;
  shareClassName: string;
  shareClassType: string;
  sharesAvailable: number;
};

export type AvailableShareClassesPostResponseDTO = {
  shareClasses: AvailableShareClass[];
};

// Split
type CommonShareholderDTO = {
  firstName: string;
  lastName: string;
  companyName: string;
  initials: string;
  isCompanyOwned: boolean;
  avatarFilePath?: string;
};

export type SplitShareholder = CommonShareholderDTO & {
  stakeholderId: number;
  sharesBefore: number;
  sharesAfter: number;
};

export type SplitPlan = CommonShareholderDTO & {
  planId: number;
  instrumentTypeId: InstrumentTypesIdsEnum;
  instrumentTypeName: InstrumentTypesNamesEnum;
  sharesBefore: number;
  sharesAfter: number;
};

export type SplitWarrant = CommonShareholderDTO & {
  warrantId: number;
  sharesBefore: number;
  sharesAfter: number;
};

export type SplitPreviewGetResponseDTO = {
  totalSharesBefore: number;
  totalSharesAfter: number;
  sharePriceBefore: number;
  sharePriceAfter: number;
  nominalShareValueBefore: number;
  nominalShareValueAfter: number;
  shareholders: SplitShareholder[];
  incentiveAgreements: SplitPlan[];
  warrants: SplitWarrant[];
  shareholdersCount: number;
  incentiveAgreementsCount: number;
  warrantsCount: number;
};

export type NominalValueGetResponseDTO = {
  transactionId: number;
  transactionDate: string;
  description?: string;
  companyId: number;
  documentStatusId: number;
  nominalShareValue: number;
  documentFiles?: Pick<SingleCommonDocumentType, "fileName" | "downloadId" | "fileId">[];
};

export type SplitPostDTO = Omit<TransactionDetailsFieldsType, "transactionTypeId"> &
  SplitFieldsTypes &
  DocumentationDetailsFieldsType & { companyId: number };

export type SplitPostResponseDTO = { transactionId: number };

export type SplitEditDTO = Omit<TransactionDetailsFieldsType, "transactionTypeId"> &
  SplitFieldsTypes & { companyId: number; transactionId: number };

export type SplitGetResponseDTO = TransactionDetailsFieldsType &
  SplitFieldsTypes & { companyId: number; transactionId: number };

export type ConfirmSplitTransactionPostDTO = { transactionId: number; transactedAt: string } & Omit<
  TransactionDetailsFieldsType,
  "transactionTypeId"
> &
  SplitFieldsTypes;

export type CapitalIncreaseGetDTO = {
  transactionBundleId: number;
  transactionId: number;
  statusId: TransactionStatusIds;
  transactions: Transaction[];
  name: string;
  sharePrice: number;
  totalIncrease: number;
  description?: string;
};

export type CapitalIncreaseTransaction = {
  transactionId?: number;
  numberOfShares: number;
  receivingShareholderId: number;
  shareClassId: number;
  sharePrice: number;
  sourceShareholderId: number;
  transactionDate: string;
  transactionType: number;
};

export type CapitalIncreaseFormGetDTO = {
  transactionId?: number;
  [CapitalIncreaseGeneralFields.transactionBundleId]?: number;
  [CapitalIncreaseGeneralFields.eventName]: string;
  [CapitalIncreaseGeneralFields.sharePrice]: number;
  [CapitalIncreaseGeneralFields.numberOfShares]: number;
  [CapitalIncreaseGeneralFields.transactedAt]: string;
  [CapitalIncreaseGeneralFields.description]?: string;
  [CapitalIncreaseTransactionsFields.transactions]: CapitalIncreaseTransaction[];
  statusId: TransactionStatusIds;
  documentFiles: {
    fileId: number;
    fileName: string;
    downloadId: string;
  }[];
};

export type CapitalIncreaseFormGeneralPostDTO = {
  companyId: number;
  [CapitalIncreaseGeneralFields.transactionBundleId]?: number;
  [CapitalIncreaseGeneralFields.eventName]: string;
  [CapitalIncreaseGeneralFields.sharePrice]: number;
  [CapitalIncreaseGeneralFields.numberOfShares]: number;
  [CapitalIncreaseGeneralFields.transactedAt]: string;
  [CapitalIncreaseGeneralFields.description]?: string;
} & DocumentationDetailsFieldsType;

export type CapitalIncreaseTransactionsPostDTO = {
  companyId: number;
  transactionBundleId: number;
  transactions: ImportTransactionFormValues[];
  transactionIdsToDelete: number[];
};

export type ConfirmCapitalIncreaseTransactionPostDTO = {
  transactionBundleId: number;
  transactedAt: string;
  description: string;
};

export type ConfirmNominalValuePostDTO = { transactionId: number; transactionDate: string; description?: string };

export type ChangeNominalValuePostDTO = {
  [ChangeNominalValueFields.companyId]: number;
  [ChangeNominalValueFields.nominalShareValue]?: number;
  [ChangeNominalValueFields.transactionDate]: string;
  [ChangeNominalValueFields.transactionId]?: number;
  [ChangeNominalValueFields.description]?: string;
} & DocumentationDetailsFieldsType;

export type UserTransactionsGetDTO = {
  transactions: Transaction[];
};
